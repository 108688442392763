import React, { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { appURL } from "../../../../config/config";
import { UserContext } from "../../../../provider/user-provider";
import "./content-section.scss";


export default function ContentSection(props) {
  const { userProfile, getUserProfile } = useContext(UserContext);
  const history = useHistory();
  const referalIdRef = useRef(null);
  const shareUrlRef = useRef(null);

  const [isCopied, setIsCopied] = useState("");
  const [isShared, setIsShared] = useState("");
  const referalId = userProfile.referral_code;
  const url = appURL + "/how-it-works?ref=" + referalId;

  function copyToClipboard(event) {
    referalIdRef.current.select();
    document.execCommand("copy");
    event.target.focus();
    setIsCopied("Copied!");
  }

  const share = () => {
    const shareDialog = document.querySelector(".share-dialog");
    shareDialog.classList.add("is-open");
  };

  const shareClose = (event) => {
    const shareDialog = document.querySelector(".share-dialog");
    shareDialog.classList.remove("is-open");
  };

  const copyText = () => {
    var copyhelper = document.createElement("input");
    copyhelper.className = "copyhelper";
    document.body.appendChild(copyhelper);
    copyhelper.value = `Hey! I’ve signed up for Syiok! Loyalty Programme and have 1 complimentary engine oil waiting for me. Want yours? Use my referral code and join me: ${url}`;
    copyhelper.select();
    document.execCommand("copy");
    document.body.removeChild(copyhelper);
    setIsShared("Copied!");
    shareClose();
    setTimeout(() => setIsShared(""), 2500);
  };

  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8">
            <p className="referal_id text-center" onClick={copyToClipboard}>
              <b>Referral ID: {referalId} </b>
              <i className="icon-copy"></i>
              {isCopied ? (
                <small className="text-success">{isCopied}</small>
              ) : (
                ""
              )}
              <input
                type="text"
                style={{
                  position: "absolute",
                  left: "-1000px",
                  top: "-1000px",
                }}
                value={referalId}
                readOnly={true}
                ref={referalIdRef}
              ></input>
            </p>
            <p>You may:</p>
            <p>
              Copy your unique referral code above to share with your friends!
            </p>
            <p>OR</p>
            <p>
              Click on your unique referral URL or the ‘Share’ icon and choose
              which app you'd like to share your code in.
            </p>

            <p>Referral URL:</p>
            <ShareButton
              isShared={isShared}
              shareClose={shareClose}
              copyToClipboard={copyToClipboard}
              share={share}
              copyText={copyText}
              url={url}
            ></ShareButton>
            <p className="mb-4">That’s it. Share it now!</p>
            <button
              className="tom-button primary-button mb-5"
              onClick={(e) => history.replace("/loyalty")}
            >
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

const ShareButton = (props) => {
  return (
    <div className="share-section">
      <div class="share-dialog">
        <header>
          <p class="dialog-title mb-0">
            <b>Share this Referal ID</b>
          </p>
          <button class="close-button" onClick={props.shareClose}>
            <svg>
              <use href="#close"></use>
            </svg>
          </button>
        </header>
        <div class="targets">
          <a
            class="button"
            href={`${"https://api.whatsapp.com/send?text=Hey!%20I%E2%80%99ve%20signed%20up%20for%20TOTAL%20Syiok!%20Loyalty%20Programme%20and%20have%201%20complimentary%20engine%20oil%20waiting%20for%20me.%20Want%20yours?%20Use%20my%20referral%20code%20and%20join%20me: " +
              props.url
              }`}
            target="_blank"
          >
            <svg>
              <use href="#whatsapp"></use>
            </svg>
            <span>whatsapp</span>
          </a>

          <a
            class="button"
            href={`mailto:?body=${"Hey!%20I%E2%80%99ve%20signed%20up%20for%20TOTAL%20Syiok!%20Loyalty%20Programme%20and%20have%201%20complimentary%20engine%20oil%20waiting%20for%20me.%20Want%20yours?%20Use%20my%20referral%20code%20and%20join%20me: " +
              props.url
              }`}
            target="_blank"
          >
            <svg>
              <use href="#email"></use>
            </svg>
            <span>Email</span>
          </a>
        </div>
        <div class="link">
          <div class="pen-url">{props.url}</div>
          <button class="copy-link" onClick={props.copyText}>
            Copy Link
          </button>
        </div>
      </div>
      <div
        class="share-button"
        className="d-flex justify-content-between referal_url mb-4"
        onClick={props.share}
        title="Share this article"
      >
        <p className="mb-0">{props.url}</p>
        <i className="icon-share-copy"></i>
        <input
          type="text"
          style={{
            position: "absolute",
            left: "-1000px",
            top: "-1000px",
          }}
        ></input>
        {props.isShared ? (
          <small className="text-success">{props.isShared}</small>
        ) : (
          ""
        )}
      </div>

      <svg class="hidden">
        <defs>
          <symbol
            id="share-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-share"
          >
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
            <polyline points="16 6 12 2 8 6"></polyline>
            <line x1="12" y1="2" x2="12" y2="15"></line>
          </symbol>

          <symbol id="whatsapp" viewBox="0 0 64 64" width="20" height="20">
            <circle cx="32" cy="32" r="31" fill="#25D366"></circle>
            <path
              d="m42.32286,33.93287c-0.5178,-0.2589 -3.04726,-1.49644 -3.52105,-1.66732c-0.4712,-0.17346 -0.81554,-0.2589 -1.15987,0.2589c-0.34175,0.51004 -1.33075,1.66474 -1.63108,2.00648c-0.30032,0.33658 -0.60064,0.36247 -1.11327,0.12945c-0.5178,-0.2589 -2.17994,-0.80259 -4.14759,-2.56312c-1.53269,-1.37217 -2.56312,-3.05503 -2.86603,-3.57283c-0.30033,-0.5178 -0.03366,-0.80259 0.22524,-1.06149c0.23301,-0.23301 0.5178,-0.59547 0.7767,-0.90616c0.25372,-0.31068 0.33657,-0.5178 0.51262,-0.85437c0.17088,-0.36246 0.08544,-0.64725 -0.04402,-0.90615c-0.12945,-0.2589 -1.15987,-2.79613 -1.58964,-3.80584c-0.41424,-1.00971 -0.84142,-0.88027 -1.15987,-0.88027c-0.29773,-0.02588 -0.64208,-0.02588 -0.98382,-0.02588c-0.34693,0 -0.90616,0.12945 -1.37736,0.62136c-0.4712,0.5178 -1.80194,1.76053 -1.80194,4.27186c0,2.51134 1.84596,4.945 2.10227,5.30747c0.2589,0.33657 3.63497,5.51458 8.80262,7.74113c1.23237,0.5178 2.1903,0.82848 2.94111,1.08738c1.23237,0.38836 2.35599,0.33657 3.24402,0.20712c0.99159,-0.15534 3.04985,-1.24272 3.47963,-2.45956c0.44013,-1.21683 0.44013,-2.22654 0.31068,-2.45955c-0.12945,-0.23301 -0.46601,-0.36247 -0.98382,-0.59548m-9.40068,12.84407l-0.02589,0c-3.05503,0 -6.08417,-0.82849 -8.72495,-2.38189l-0.62136,-0.37023l-6.47252,1.68286l1.73463,-6.29129l-0.41424,-0.64725c-1.70875,-2.71846 -2.6149,-5.85116 -2.6149,-9.07706c0,-9.39809 7.68934,-17.06155 17.15993,-17.06155c4.58253,0 8.88029,1.78642 12.11655,5.02268c3.23625,3.21036 5.02267,7.50812 5.02267,12.06476c-0.0078,9.3981 -7.69712,17.06155 -17.14699,17.06155m14.58906,-31.58846c-3.93529,-3.80584 -9.1133,-5.95471 -14.62789,-5.95471c-11.36055,0 -20.60848,9.2065 -20.61625,20.52564c0,3.61684 0.94757,7.14565 2.75211,10.26282l-2.92557,10.63564l10.93337,-2.85309c3.0136,1.63108 6.4052,2.4958 9.85634,2.49839l0.01037,0c11.36574,0 20.61884,-9.2091 20.62403,-20.53082c0,-5.48093 -2.14111,-10.64081 -6.03239,-14.51915"
              fill="white"
            ></path>
          </symbol>

          <symbol
            id="facebook"
            viewBox="0 0 24 24"
            fill="#3b5998"
            stroke="#3b5998"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-facebook"
          >
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
          </symbol>

          <symbol
            id="twitter"
            viewBox="0 0 24 24"
            fill="#1da1f2"
            stroke="#1da1f2"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-twitter"
          >
            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
          </symbol>

          <symbol
            id="email"
            viewBox="0 0 24 24"
            fill="#777"
            stroke="#fafafa"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </symbol>

          <symbol
            id="linkedin"
            viewBox="0 0 24 24"
            fill="#0077B5"
            stroke="#0077B5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-linkedin"
          >
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
            <rect x="2" y="9" width="4" height="12"></rect>
            <circle cx="4" cy="4" r="2"></circle>
          </symbol>

          <symbol
            id="close"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-square"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="9" x2="15" y2="15"></line>
            <line x1="15" y1="9" x2="9" y2="15"></line>
          </symbol>
        </defs>
      </svg>
    </div>
  );
};
