import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import "./qr-generate.scss";
import QRCode from "react-qr-code";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { PopupContext } from "../../../../../../provider/popup-provider";
import TagManager from "react-gtm-module";

export default function QRGenerateComponent(props) {
  const history = useHistory();
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { service, setService } = useContext(AddServiceContext);

  function verifyLaterService() {
    const popupVal = Object.assign({}, popup);
    popupVal.open = true;
    popupVal.img = "pending-icon";
    popupVal.content = ["Your workshop will verify the service later."];
    popupVal.buttons = [
      {
        type: "primary",
        text: "OK",
        tap: () => {
          TagManager.dataLayer({ dataLayer: { event: 'Step 4 service Summary', cta_button: 'verify later' } });
          history.replace("/loyalty");
        },
      },
    ];
    setPopup(popupVal);
  }

  return <section className="qr-generate-component">
    <div className="container">
      <div className="row">
        <div className="offset-md-2 col-12 col-md-8 mb-3 px-4 px-md-0">
          <p>Show QR code or Service ID to workshop.
        <br /> <b>DO NOT</b> close the window until the process is complete.</p>
        </div>
        <div className="offset-md-4 col-12 col-md-4 mb-3 px-4 px-md-0 mb-5">
          <div className="qr-box">
            <QRCode value={service.transaction_code} />
            <p className="mb-0"><b>ID: {service.transaction_code}</b></p>
          </div>
        </div>
        <div className="offset-md-2 col-12 col-md-8 mb-3 px-4 px-md-0">
          <button
            className="tom-button default-button"
            id="go-back"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: 'Step 4 service Summary', cta_button: 'go back to select variant' } });
              history.replace({pathname: "/loyalty"});
            }}
          >
            Go back
        </button>
        </div>
      </div>
    </div>
  </section>;
}
