import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddServiceContextProvider from "../../../../provider/add-service-provider";
import TopSectionScreen from "../../../general-screen/top-section/top-section";
import "./add-service-screen.scss";

export default function AddServiceScreen(props) {
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.replace("/loyalty");
    }
  }, []);
  return (
    <div className="add-service-screen">
      {props.location.state !== undefined ? (
        <AddServiceContextProvider carInfo={props.location.state}>
          <TopSectionScreen title="Add Service"></TopSectionScreen>
        </AddServiceContextProvider>
      ) : (
        ""
      )}
    </div>
  );
}
