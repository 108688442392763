import React from "react";
import "./login-screen.scss";
import LoginFormComponent from "./login-form/login-form";
import WelcomeComponent from "../welcome/welcome";
import PopupContextProvider from "../../../provider/popup-provider";

export default function LoginScreen(props) {

  let path =  props.location.state != null ? props.location.state.from.pathname : '/loyalty';
  let query =  props.location.state != null ? props.location.state.from.search : '';
  let redirect_url = path + query;

  return (
    <div className="login-screen">
      <PopupContextProvider>
          <WelcomeComponent></WelcomeComponent>
          <LoginFormComponent redirect_to={redirect_url}></LoginFormComponent>
      </PopupContextProvider>
    </div>
  );
}
