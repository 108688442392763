import Axios from "axios";
import React, { useEffect, useState } from "react";
import { apiURL } from "../../../../../config/config";
import "./service-product-detail.scss";
import ServiceProductListComponent from "./service-product-list";

export default function ServiceProductDetailComponent(props) {
  const [services, setServices] = useState();

  useEffect(() => {
    const getCycleServices = async () => {
      try {
        const response = await Axios.get(
          `${apiURL}/api/cycle/${props.cycle}/services`
        );

        let result = response.data.result;

        if (Array.isArray(result)) {
          if (result.length > 0) {
            let sortedServices = result.sort(function compare(a, b) {
              var dateA = new Date(a.created_date);
              var dateB = new Date(b.created_date);

              return dateB - dateA;
            });

            setServices(sortedServices);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (props.cycle != null) {
      getCycleServices();
    }
  }, [props.cycle]);

  return (
    <section className="service-product-detail-component">
      <div className="container px-4">
        <div className="row pt-5 pt-md-0">
          <ServiceProductListComponent
            services={services}
            vehicle_info={props.vehicle_info}
          ></ServiceProductListComponent>
        </div>
      </div>
    </section>
  );
}
