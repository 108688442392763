import Axios from "axios";
import Cookies from 'js-cookie';
import React, { createContext, useState, useEffect } from "react";
import { apiURL } from "../config/config";

export const UserContext = createContext();

const UserContextProvider = props => {
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [userProfile, setUserProfile] = useState({
        address: null,
        country: null,
        birth_date: null,
        email: null,
        mobile_number: null,
        fullname: null,
        password: null,
        postal_code: null,
        state: null,
        auth_provider: null,
        referral_code: null,
    });

    useEffect(() => {
        let profile = JSON.parse(localStorage.getItem("user_profile"));
        
        if (profile != null) {
            setUserProfile(profile);
        }
    }, []);

    const logout = async () => {
        try {
            const response = await Axios.get(`${apiURL}/api/user/logout`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                },
            });

            if (response.data.status_code == 200) {
                Cookies.remove('access_token');
                Cookies.remove('refresh_token');
                sessionStorage.clear();
                localStorage.clear();
                setUserProfile({
                    address: null,
                    country: null,
                    birth_date: null,
                    email: null,
                    mobile_number: null,
                    fullname: null,
                    password: null,
                    postal_code: null,
                    state: null,
                    auth_provider: null,
                    referral_code: null,
                });
                window.location.href = "/login";
            } else {
                console.log(response.error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUserProfile = async () => {
        try {
            const response = await Axios.get(`${apiURL}/api/user/me`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                },
            });

            var result = response.data.result;

            localStorage.setItem("user_profile", JSON.stringify(result));

            setUserProfile({
                address: result.address,
                country: result.country,
                birth_date: result.birth_date,
                email: result.email,
                mobile_number: result.mobile_number,
                fullname: result.fullname,
                password: "",
                postal_code: result.postal_code,
                state: result.state,
                auth_provider: result.auth_provider,
                referral_code: result.referral_code,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <UserContext.Provider value={{ loadingIndicator, userProfile, getUserProfile, logout, setUserProfile }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;