import Axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../../config/config";

export const verifiedOTPAPI = async (mobile_number, tac) => {
    try {
        const response = await Axios.post(
            `${apiURL}/api/user/verification`,
            querystring.stringify({
                mobile_number,
                tac
            })
        );

        return response.data;
    } catch (error) {
      return {
        status_code: 502
      }
    }
}