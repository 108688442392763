import React, { createContext, useState } from "react";
import StepOneComponent from "../screens/dashboard-screen/loyalty-dashboard/add-service-screen/step-1/step-1";
import StepFourComponent from "../screens/dashboard-screen/loyalty-dashboard/add-service-screen/step-4/step-4";

export const AddServiceContext = createContext();

const AddServiceContextProvider = (props) => {
  let [service, setService] = useState({
    guid: props.carInfo.guid,
    cycle_guid: props.carInfo.cycle_guid,
    car_name: props.carInfo.car_name,
    plate_number: props.carInfo.plate_number,
    screen:
      props.carInfo.set_screen !== "four" ? (
        <StepOneComponent></StepOneComponent>
      ) : (
        <StepFourComponent></StepFourComponent>
      ),
    workshop_id: {
      ori: props.carInfo.workshop_id,
      floating: props.carInfo.workshop_id,
      new_workshop_id: "",
    },
    current_mileage: props.carInfo.current_mileage,
    oil: { ori: props.carInfo.oil_code, float: props.carInfo.oil_code },
    cycle_oil_type: props.carInfo.cycle_oil_type,
    bottle_no: props.carInfo.bottle_no,
    receipt_img: null,
    transaction_code: props.carInfo.transaction_code,
    service_date: props.carInfo.service_date,
    free_oil: props.carInfo.free_oil,
    tab_oil: props.carInfo.cycle_oil_type == 4 ? "tab-2" : "tab-1",
    current_service: {
      ori: props.carInfo.current_service,
      floating: props.carInfo.current_service,
    },
    current_oils: props.carInfo.current_oils,
    selection: {
      current_oils: []
    },
    scrollTop: 0,
  });

  return (
    <AddServiceContext.Provider value={{ service, setService }}>
      {props.children}
      {service.screen}
    </AddServiceContext.Provider>
  );
};

export default AddServiceContextProvider;
