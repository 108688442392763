import React from "react";
import CeasedPopupContextProvider from "../../../../../provider/ceased-form-popup-provider";
import PopupContextProvider from "../../../../../provider/popup-provider";
import StepOneFormComponent from "./step-1-form/step-1-form";
import "./step-1.scss";

export default function StepOneComponent(props) {
  return (
    <PopupContextProvider>
      <CeasedPopupContextProvider>
        <StepOneFormComponent></StepOneFormComponent>
      </CeasedPopupContextProvider>
    </PopupContextProvider>
  );
}
