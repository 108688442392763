import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../../../helpers/moment-helper";

export default function ServiceProductListComponent(props) {
  let services = props.services;
  const history = useHistory();
  
  return (
    <Fragment>
      {services != null && services.length > 0 ? (
        services.map((service, index) => {
          return (
            <div 
            key={index} 
            className="col-12 col-md-6 mb-5"
            >
              <div className="row product">
                <div className="col-5 pr-0 product-image my-auto">
                  <img src={service.oil_img} width="100%" />
                </div>
                <div className="col-7 px-0 my-auto">
                  <p className="mb-0">
                    Engine oil: <br />
                    <b>{service.oil_name}</b>{" "}
                  </p>
                  <p className="mb-0">
                    Last service mileage: <br />
                    <b>{service.current_mileage.toLocaleString()}km</b>
                  </p>
                  <p className="mb-0">
                    {" "}
                    Last service date: <br />
                    <b>{formatDate(service.created_date)}</b>
                  </p>
                  <p className="mb-0">
                    Next service mileage: <br />
                    <b>{service.next_mileage.toLocaleString()}km</b>
                  </p>
                  <p className="mb-0">
                    Next service date: <br />
                    <b>{formatDate(service.next_service_date)}</b>{" "}
                  </p>
                  <p className="mb-0">
                    Transaction code: <br />
                    <b>{service.transaction_code}</b>{" "}
                  </p>
                  <p className="mb-0">
                    Status: <br />
                    <ServiceProductDetailStatus
                      status={service.status}
                      reject_reason={service.reject_reason}
                    ></ServiceProductDetailStatus>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6 offset-3">
                  <button
                    className="tom-button default-button mt-4"
                    onClick={(event) => {
                      event.preventDefault();
                      window.location.href = service.receipt_img;
                    }}
                  >
                    <small>VIEW RECEIPT </small>
                    <span>
                      <i className="icon-file"></i>
                    </span>
                  </button>
                </div>
              </div>
              {service.status == "0" ? 
              <div className="row">
                <div className="col-6 offset-3">
                  <button
                    className="tom-button primary-button mt-3"
                    onClick={() =>
                      history.push({
                        pathname: "/loyalty/add-service",
                        state: {
                          guid: props.vehicle_info.guid,
                          cycle_guid: props.vehicle_info.cycle_guid,
                          car_name: `${props.vehicle_info.model_brand} ${props.vehicle_info.model_name} ${props.vehicle_info.plate_number}`,
                          plate_number: props.vehicle_info.plate_number,
                          workshop_id: props.vehicle_info.workshop_id,
                          cycle_oil_type: props.vehicle_info.cycle_oil_type,
                          oil_code: props.vehicle_info.oil_code,
                          free_oil: props.vehicle_info.free_oil,
                          current_service: props.vehicle_info.services?.length,
                          set_screen: "four",
                          service_date:
                          formatDate(service.created_date),
                          last_service_date:
                          formatDate(service.created_date),
                          current_mileage: +service.current_mileage,
                          transaction_code:
                          service.transaction_code,
                          bottle_no: services.filter(x => x.transaction_code == service.transaction_code).length,
                          current_oils: services.map(x => x.OilCode)
                        },
                      })
                    }
                  >
                    <small>Generate QR CODE</small>
                  </button>
                </div>
              </div>
              : ""}
            </div>
          );
        })
      ) : (
        <div className="col">
          <p className="text-center">Not serviced</p>
        </div>
      )}
    </Fragment>
  );
}

function ServiceProductDetailStatus(props) {
  let statusImg;
  let statusText;

  switch (props.status) {
    case 0:
      statusImg = "/assets/images/time.png";
      statusText = "Pending";
      break;
    case 1:
      statusImg = "/assets/images/check-mark.png";
      statusText = "Verified";
      break;
    case 2:
      statusImg = "/assets/images/cancel.png";
      statusText = "Rejected: " + props.reject_reason;
      break;
    case 3:
      statusImg = "/assets/images/cancel.png";
      statusText = "Archived";
      break;
    default:
      break;
  }
  return (
    <Fragment>
      <img src={statusImg} /> &nbsp; {statusText}
    </Fragment>
  );
}
