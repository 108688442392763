import axios from "axios";
import Cookies from 'js-cookie';
import querystring from "querystring";
import { apiURL } from "../../../../../config/config";

export const AddVehicleAPI = async (
    model_brand, model_name, plate_number, mileage, year_manufacture) => {
  try {
    const response = await axios.post(
      `${apiURL}/api/vehicle`,
      querystring.stringify({
        model_brand: model_brand,
        model_name: model_name,
        plate_number: plate_number,
        year_manufactured: year_manufacture,
        mileage: mileage,    
      }),
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
      }
    );
    return response.data;
  } catch (error) {
    return {
      status_code: 502
    }
  }
};