import React from "react";
import TopSectionScreen from "../top-section/top-section";
// import ContentSection from "./content-section/content-section";
import "./privacy-policy.scss";

export default function PrivacyPolicyScreen(props) {
  return (
    <div className="privacy-policy-screen">
      <TopSectionScreen title="Privacy Policy"></TopSectionScreen>
      <section className="content-section">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12">
              <div className="px-md-4">
                <h2 className="mb-4">
                  Quartz Syiok! Loyalty Programme Website and Mobile Application
                </h2>
                <p>
                  Welcome to the Quartz Syiok! Loyalty Programme Website{" "}
                  <a href="https://www.syiok.totalenergies.my">
                    www.syiok.totalenergies.my{" "}
                  </a>{" "}
                  and/or the Quartz Syiok! Workshop Mobile Application. By
                  connecting to or accessing the website and/or
                  downloading/using mobile application, you agree that you have
                  read, understood and accepted, without limitation or
                  reservation, this Privacy Policy (“Policy”) and our Quartz
                  Syiok! Loyalty Programme Terms of Use. Please note that other
                  terms and conditions and/or privacy policy may be applicable
                  to other TotalEnergies Group websites, and we recommend that
                  you read them carefully. The Policy aims to inform you of the
                  rights and freedoms that you can exercise in respect of our
                  use of your personal data and describes the measures that we
                  take to protect these data. TotalEnergies Marketing Malaysia
                  Sdn. Bhd. (199401041979 (327667-U)) is the “data controller”
                  with responsibility for processing the personal data used to
                  manage the website and mobile application. These processing
                  operations are carried out in accordance with the applicable
                  law.{" "}
                </p>
                <ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Purpose of processing, legal basis, the period of
                        storage, and types of data collected:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li className="mb-4">
                      When you access the Quartz Syiok! Loyalty Programme
                      Website and/or downloading/using the Quartz Syiok!
                      Workshop Mobile Application, you may be required to
                      provide us with your personal data, such as your first
                      name and surname, mobile number, email address and
                      residential or office address, in order to use the
                      services available. By keying in your personal data, you
                      consent to us and the data controller collecting and
                      processing your personal data for the services. Giving us
                      your consent to process your personal data enables us to
                      process your request. Without it, the information cannot
                      be processed. You can withdraw your consent at any time
                      without jeopardising any processing operations that have
                      already been completed, by writing to the following
                      address: Level 2, Wisma Kemajuan, 2, Jalan 19/1, Seksyen
                      19, 46300 Petaling Jaya, Selangor.
                    </li>
                    <li className="mb-4">
                      Your personal data will not be processed subsequently in a
                      way that is incompatible with the purposes described in
                      the paragraph above and/or in the relevant collection
                      forms. Your personal data will be stored by us for no
                      longer than four (4) years with effect from your most
                      recent contact with TotalEnergies Marketing Malaysia Sdn.
                      Bhd. (199401041979 (327667-U)), and may, if applicable, be
                      stored in encrypted database for the applicable statutory
                      period.
                    </li>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Recipients of data:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li className="mb-4">
                      Your personal data may be communicated to one or more of
                      the data controller's departments or to TotalEnergies
                      Group companies and one or more of its partners or
                      independent distributors.
                    </li>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Data Transfer:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li className="mb-4">
                      Any transfer of personal data to a country outside
                      Malaysia shall be carried out in accordance with the
                      applicable data protection regulations, including the
                      Personal Data Protection Act 2010 (“PDPA”), and in such a
                      way as to protect your personal data appropriately. For
                      the purposes of the services provided on this website,
                      your contact information may be transferred to the
                      following recipients: Partners, TotalEnergies Group
                      Companies or distributors located outside Malaysia. By
                      providing us with your personal data, you consent to us
                      disclosing your personal data to those third parties
                      and/or the transfer of your personal data outside of
                      Malaysia.
                    </li>
                    <li className="mb-4">
                      The TotalEnergies Group has adopted “Binding Corporate
                      Rules” (BCR) governing intra-Group transfers of personal
                      data, which is accessible here:{" "}
                      <a href="https://totalenergies.com/privacy">
                        https://totalenergies.com/privacy
                      </a>
                      . For data transfers not covered by the BCR, reasonable
                      steps will be taken by us to ascertain that the foreign
                      recipient(s) of the personal data is bound by legally
                      enforceable obligations to provide to the transferred
                      personal data a standard of protection that is at least
                      comparable to the protection under the PDPA.
                    </li>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Data Security and Confidentiality:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li className="mb-4">
                      The data controller takes appropriate steps to preserve
                      the security and confidentiality of your personal data,
                      including preventing them from being distorted, damaged,
                      or disclosed to unauthorized third parties.
                    </li>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Cookie Management:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li>Principle</li>
                    <ol type="i" className="mb-4">
                      <li>
                        A cookie is a file which enables a website to save the
                        information relating to your computer’s browsing of the
                        website visits, including but not limited to the number
                        of pages viewed, to make your visits to the website
                        smoother.
                      </li>
                      <li>
                        You can at any time delete the cookies stored on your
                        computer, object to the storage of new cookies, and/or
                        receive a notification before new cookies are stored by
                        changing your browser settings using the instructions
                        below (“Types of cookies, cookies and statistics, and
                        settings”).
                      </li>
                      <li>
                        Please note that if you remove a cookie or object to the
                        storage of cookies on your device, you may not be able
                        to use some of the website's services.
                      </li>
                    </ol>
                    <li>
                      Types of cookies, cookies and statistics, and settings:
                    </li>
                    <ol type="i">
                      <li>
                        The cookies that may be stored on your server when you
                        browse the website are cookies which are intended solely
                        to enable or facilitate electronic communication or
                        which are strictly necessary to provide the service you
                        are requesting (language cookies, login cookies, etc.),
                        statistics cookies, or other cookies in accordance with
                        the conditions below.
                      </li>
                      <li className="mb-4">
                        When cookies require your agreement before they can be
                        saved, we ask you for this agreement via the “find out
                        more” link displayed on the first page of the website
                        that you land on, in which it is made clear that by
                        continuing to browse the website you accept these
                        cookies.
                      </li>
                    </ol>
                    <li>
                      How do you delete cookies, manage site-specific cookies,
                      block cookies, or change your browser settings?
                    </li>
                    <ol type="i">
                      <li>
                        How do you delete cookies already stored on your
                        computer or on your internet browser?
                      </li>
                      <ul className="mb-4">
                        <li style={{ listStyleType: "disc" }}>
                          On your Windows workstation:
                        </li>
                        <ul style={{ listStyleType: "square" }}>
                          <li>On the C:\ drive select the Windows folder;</li>
                          <li>Open the “Temporary Internet Files” folder;</li>
                          <li>Select all files (CTRL A);</li>
                          <li>Choose the “delete” option.</li>
                        </ul>
                        <li style={{ listStyleType: "disc" }}>
                          On your browser:
                        </li>
                        <ul>
                          <li style={{ listStyleType: "square" }}>
                            Clear the browsing history of your browser making
                            sure the cookies checkbox is ticked before clearing.
                            This will remove all cookies from all websites you
                            have visited.
                          </li>
                        </ul>
                        <li style={{ listStyleType: "disc" }}>
                          Do note that the foregoing action may also lead to
                          deletion of your saved information (e.g. saved login
                          details, site preferences) from the relevant browser.
                        </li>
                      </ul>
                      <li>How do you manage site-specific cookies?</li>
                      <ul className="mb-4">
                        <li style={{ listStyleType: "disc" }}>
                          This can be achieved through updating the settings of
                          your internet browser. For more detailed control over
                          site-specific cookies, check the privacy and cookie
                          settings in your preferred browser.
                        </li>
                      </ul>
                      <li>How do you block cookies?</li>
                      <ul className="mb-4">
                        <li style={{ listStyleType: "disc" }}>
                          In most of today’s modern browsers, you can choose to
                          customize your browser settings to prevent any cookies
                          from being placed on your device. In doing so, please
                          note you may have to manually adjust some preferences
                          every time you visit a site/page; Some services and
                          functionalities of the browser may also not work
                          properly (e.g. profile logging-in) following your
                          customization of the settings.
                        </li>
                      </ul>
                    </ol>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Your Rights/Contact:
                      </p>
                    </li>
                  </b>
                  <ol type="a">
                    <li className="mb-4">
                      In accordance with current regulations, you have the right
                      to access, rectify, delete, and object to the use of your
                      personal data. You can ask for your personal data to be
                      sent to you and you have the right to give instructions
                      for the use of your personal data after your death. You
                      can also ask for restriction of the data processing
                      activity.
                    </li>
                    <li className="mb-4">
                      You can exercise your rights and ask us about the
                      processing of your personal data by writing to L-5-10,
                      Solaris Mont Kiara, Jalan Solaris, 50480 Mont Kiara, Kuala
                      Lumpur, Malaysia or contact the Quartz Syiok! Loyalty
                      Programme Customer Support at{" "}
                      <a href="mailto:customersupport@totalenergies.com">
                        customersupport@totalenergies.com
                      </a>
                    </li>
                  </ol>
                  <b>
                    <li>
                      <p style={{ fontSize: "1rem", fontFamily: "Nunito" }}>
                        Collection of personal data or information by third
                        parties:
                      </p>
                    </li>
                  </b>
                  <p>
                    Our website may contain links to other sites, including,
                    among others, websites of our customers. These third-party
                    sites may have privacy policies that may be different from
                    ours. Visitors using such links should consult the privacy
                    notices associated with those links. Please keep in mind
                    that whenever you share personal information online — for
                    example, via message boards or chat rooms — that information
                    may be collected, intercepted and used by people you do not
                    know. Our policies do not extend to anything that is
                    inherent in the operation of the Internet and therefore
                    beyond our control. We and the data controller,
                    TotalEnergies Marketing Malaysia Sdn. Bhd. (199401041979
                    (327667-U)), take no responsibility for information
                    collected by third parties through the foregoing channels.
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
