export const fullOilInfo = [
    {
        code: 'QUARTZINEOHTC5W30',
        rank: 12,
        name: 'QUARTZ INEO HTC (5L)',
        be_name: 'QUARTZ INEO HTC (5 Litre)',
        full_name: ['QUARTZ INEO HTC', '(5 Litre)'],
        desc: 'Highly advanced formulation that’s suitable for diesel & turbocharged gasoline engines.',
        pdf_name: 'QUARTZ INEO HTC 5W-30',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/skp-quartz-ineo-htc-5w-30'
    },
    {
        code: 'QUARTZINEOMC35W30',
        rank: 12,
        name: 'QUARTZ INEO MC3 (5L)',
        be_name: 'QUARTZ INEO MC3 (5 Litre)',
        full_name: ['QUARTZ INEO MC3', '(5 Litre)'],
        desc: 'Formulated with Low SAPS technology that’s fuel saving.',
        pdf_name: 'QUARTZ INEO MC3 5W-30',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/lky-quartz-ineo-mc3-5w-30'
    },
    {
        code: 'QUARTZ9000CTI45W30',
        rank: 11,
        name: `QUARTZ DIESEL \n 9000 CI-${'\u2060'}4 \n 5W-30 (6L)`,
        be_name: 'QUARTZ DIESEL 9000 CI-4 5W-30 (6 Litre)',
        full_name: [`QUARTZ DIESEL 9000 CI-${'\u2060'}4 5W-30 (6 Litre)`],
        desc: 'Super premium heavy duty engine oil for old and new diesel engines, including those with EGR system.',
        pdf_name: 'TDS_TOTAL_QUARTZ DIESEL 9000 CI-4 5W-30_E8H_202009_EN',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/e8h-quartz-9000-diesel-ci-4-5w-30'
    },
    {
        code: 'QUARTZ9000ENERGY0W40',
        rank: 10,
        name: `QUARTZ 9000 \n ENERGY \n 0W-${'\u2060'}40 (4L)`,
        be_name: 'QUARTZ 9000 ENERGY 0W-40 (4 Litre)',
        full_name: [`QUARTZ 9000 ENERGY 0W-${'\u2060'}40`],
        desc: 'Outstanding engine protection that’s a great fit for European performance cars.',
        pdf_name: 'QUARTZ 9000 ENERGY 0W-40',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/by3-quartz-9000-energy-0w-40'
    },
    {
        code: 'QUARTZ9000FUTUREGF55W30',
        rank: 9,
        name: `QUARTZ 9000 FUTURE GF6 5W-${'\u2060'}30 (4L)`,
        be_name: 'QUARTZ 9000 FUTURE GF6 5W-30 (4 Litre)',
        full_name: [`QUARTZ 9000 FUTURE GF6 5W-${'\u2060'}30`],
        desc: 'Formulated for hybrid or “downsized” engines with Stop & Start technologies.',
        pdf_name: 'QUARTZ 9000 FUTURE GF6 5W-30',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/v88-quartz-9000-future-gf6-5w-30'
    },
    {
        code: 'QUARTZ90005W40',
        rank: 8,
        name: `QUARTZ 9000 5W-${'\u2060'}40 (4L)`,
        be_name: 'QUARTZ 9000 5W-40 (4 Litre)',
        full_name: [`QUARTZ 9000 5W-${'\u2060'}40`, ],
        desc: 'Suitable for turbo-compressed, multi-valve, direct injection diesel & gasoline engines.',
        pdf_name: 'QUARTZ 9000 5W-40',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/714-quartz-9000-5w-40'
    },
    {
        code: 'QUARTZ90005W50',
        rank: 8,
        name: `QUARTZ 9000 5W-${'\u2060'}50 (4L)`,
        be_name: 'QUARTZ 9000 5W-50 (4 Litre)',
        full_name: [`QUARTZ 9000 5W-${'\u2060'}50`],
        desc: 'Ultimate engine protection that gives unparalleled stability & endurance under extreme conditions.',
        pdf_name: 'QUARTZ 9000 5W-50',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/9qf-quartz-9000-sn-5w-50'
    },
    {
        code: 'QUARTZ90000FUTUREGF50W20',
        rank: 7,
        name: `QUARTZ 9000 FUTURE GF6 0W-${'\u2060'}20 (4L)`,
        be_name: 'QUARTZ 9000 FUTURE GF6 0W-20 (4 Litre)',
        full_name: [`QUARTZ 9000 FUTURE GF6 0W-${'\u2060'}20`],
        desc: 'Formulated for hybrid or “downsized” engines with Stop & Start technologies.',
        pdf_name: 'QUARTZ 9000 FUTURE GF6 0W-20',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/v81-quartz-9000-future-gf6-0w-20'
    },
];

export const semiOilInfo = [
    {
        code: 'QUARTZ7000CI410W30',
        rank: 6,
        name: `QUARTZ DIESEL \n 7000 CI-${'\u2060'}4 \n 10W-${'\u2060'}30 (6L)`,
        be_name: 'QUARTZ DIESEL 7000 CI-4 10W-30 (6 Litre)',
        full_name: [`QUARTZ DIESEL 7000 CI-${'\u2060'}4 10W-${'\u2060'}30 (6 Litre)`],
        desc: 'Ultra-high performance lubricant, specially formulated for pickups, vans, mini-buses, and other commercial vehicles.',
        pdf_name: 'TDS_TOTAL_QUARTZ DIESEL 7000 CI-4 10W-30_E6R_202009_EN',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/e6r-quartz-7000-diesel-ci-4-10w-30'
    },
    {
        code: 'QUARTZ8000FUTUREGF65W30',
        rank: 5,
        name: `QUARTZ 8000 FUTURE GF6 5W-${'\u2060'}30 (4L)`,
        be_name: 'QUARTZ 8000 5W-30 (4 Litre)',
        full_name: [`QUARTZ 8000 FUTURE GF6 5W-${'\u2060'}30`],
        desc: 'High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.',
        pdf_name: 'TDS_TOTAL_QUARTZ 8000 FUTURE GF6 5W-30_V80_202009_EN',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/v80-quartz-8000-future-gf6-5w-30'
    },
    {
        code: 'QUARTZ80005W40',
        rank: 4,
        name: `QUARTZ 8000 5W-${'\u2060'}40 (4L)`,
        be_name: 'QUARTZ 8000 5W-40 (4 Litre)',
        full_name: [`QUARTZ 8000 5W-${'\u2060'}40`],
        desc: 'High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.',
        pdf_name: 'TDS_TOTAL_QUARTZ 8000 5W-40_SXJ_202009_EN',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/sxj-quartz-8000-5w-40'
    },
    {
        code: 'QUARTZ8000GF60W20',
        rank: 3,
        name: `QUARTZ 8000 FUTURE GF6 0W-${'\u2060'}20 (3L)`,
        be_name: 'QUARTZ 8000 FUTURE GF6 0W-20 (3 Litre)',
        full_name: [`QUARTZ 8000 FUTURE GF6 0W-${'\u2060'}20 (3 Litre)`],
        desc: 'Fully-synthetic product suitable for “downsized”, Stop and Start engines, as well as hybrid engines.',
        pdf_name: 'TDS_TOTAL_QUARTZ 8000 FUTURE GF6 0W-20_V7Z_202009_EN',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/v7z-quartz-8000-future-gf6-0w-20'
    },
    {
        code: 'QUARTZ70005W30',
        rank: 2,
        name: `QUARTZ 7000 FUTURE GF6 5W-${'\u2060'}30 (4L)`,
        be_name: 'QUARTZ 7000 FUTURE GF6 5W-30 (4 Litre)',
        full_name: [`QUARTZ 7000 FUTURE GF6 5W-${'\u2060'}30`],
        desc: 'Significant improvement to engines against wear & deposits, performances, & oil drain intervals.',
        pdf_name: 'QUARTZ 7000 FUTURE GF6 5W-30',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/v7y-quartz-7000-future-gf6-5w-30'
    },
    {
        code: 'QUARTZ700015W50',
        rank: 1,
        name: `QUARTZ 7000 15W-${'\u2060'}50 (4L)`,
        be_name: 'QUARTZ 7000 15W-50 (4 Litre)',
        full_name: [`QUARTZ 7000 15W-${'\u2060'}50`],
        desc: 'High performance multi-grade oil, suitable for for both gasoline and diesel engines & various operating conditions.',
        pdf_name: 'QUARTZ 7000 SN 15W-50',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/8u5-quartz-7000-sn-15w-50'
    },
    {
        code: 'QUARTZ700010W40',
        rank: 1,
        name: `QUARTZ 7000 10W-${'\u2060'}40 (4L)`,
        be_name: 'QUARTZ 7000 10W-40 (4 Litre)',
        full_name: [`QUARTZ 7000 10W-${'\u2060'}40`],
        desc: 'Suitable for both gasoline & diesel engines without diesel particulate filters.',
        pdf_name: 'QUARTZ 7000 SN 10W-40',
        read_more_url: 'https://lubricants.catalog.totalenergies.com/catalog-my/en_MY/8tz-quartz-7000-sn-10w-40'
    },
]

