import React from "react";
import TopSectionScreen from "../top-section/top-section";
import ContentSection from "./content-section/content-section";
import "./semi-syntetic.scss";

export default function SemiSynteticScreen(props) {
    return (
        <div className="semi-syntetic-screen">
            <TopSectionScreen title="Products"></TopSectionScreen>
            <ContentSection></ContentSection>
        </div>
    );
}
