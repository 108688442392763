import React, {
  useRef,
  useState,
  Fragment,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import {
  maxLengthMessage,
  minLengthMessage,
  requiredMessage,
} from "../../../../helpers/form-validation-message";
import "./edit-profile-form.scss";
import { apiURL } from "../../../../config/config";
import axios from "axios";
import Cookies from "js-cookie";
import querystring from "querystring";
import {
  formatDate,
  formatDateDay,
  formatDateForm,
  formatDateMonth,
  formatDateYear,
} from "../../../../helpers/moment-helper";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { UserContext } from "../../../../provider/user-provider";
import { togglePassword } from "../../../../helpers/toggle-password";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import { PopupContext } from "../../../../provider/popup-provider";
import SelectDatepicker from "react-select-datepicker";
import { errorMessageHandler } from "../../../../helpers/error-handler";

export default function EditProfileForm() {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { register, handleSubmit, watch, errors, setError } = useForm();
  const history = useHistory();
  const tooltip_edit_phone_number = useRef();
  const { userProfile, getUserProfile } = useContext(UserContext);
  const [dob, setDOB] = useState();
  const watchPasssword = watch("password", "");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userProfile?.birth_date !== null) {
      setDOB(new Date(+userProfile?.birth_date.substring(0, 4),
        +userProfile?.birth_date.substring(5, 7) - 1, +userProfile?.birth_date.substring(8, 10)))
    }
  }, [userProfile])

  // Submit form
  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isLoading) {
      try {
        const response = await axios.put(
          `${apiURL}/api/user/me`,
          querystring.stringify(data),
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        );

        if (response.data.status_code == 200) {
          // Update user context
          await getUserProfile();
          history.goBack();
        } else if (response.data.status_code == 400) {
          setError('old_password', {
            type: "manual",
            message: response.data.error + ". Please make sure your old password is correct."
          });
          setIsLoading(false);
        } else {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          let msg = errorMessageHandler(response.data);
          popupVal.content = [msg];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "TRY AGAIN",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
        }
      } catch (error) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "TRY AGAIN",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      }
    }
  };

  const onTomTooltip = () => {
    tooltip_edit_phone_number.current.classList.add("active");
  };

  const onCloseTooltip = () => {
    tooltip_edit_phone_number.current.classList.remove("active");
  };

  const onDateChange = useCallback((date) => {
    if (date !== null) {
      setDOB(date);
    }
  }, []);

  return (
    <section className="edit-profile-form">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please provide your full name as per NRIC",
                    },
                    pattern: {
                      value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-/, ])*$/,
                      message: "*Please provide your full name as per NRIC",
                    },
                    maxLength: {
                      value: 60,
                      message: "*Please provide your full name as per NRIC",
                    },
                  })}
                  id="full-name"
                  className="tom-input"
                  name="fullname"
                  placeholder="Full Name"
                  autoComplete="off"
                  defaultValue={userProfile.fullname}
                  maxLength="60"
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="fullname" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please fill out this field",
                    },
                    pattern: {
                      value: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
                      message: "*Invalid Date of Birth",
                    },
                  })}
                  style={{ display: "none" }}
                  name="birth_date"
                  defaultValue={
                    dob !== "Invalid Date" && !isNaN(new Date(dob))
                      ? formatDateForm(dob)
                      : userProfile.birth_date != null
                        ? userProfile.birth_date
                        : ""
                  }
                />
                <div className="d-md-inline-flex pt-2 pt-md-0">
                  <span className="mt-md-2 mr-md-4">Date of Birth:</span>
                  <SelectDatepicker
                    value={dob}
                    onDateChange={onDateChange}
                    minDate={new Date(1930, 1, 1)}
                    maxDate={
                      new Date(+formatDateYear(new Date()) - 18, +11, 31)
                    }
                    showLabels={false}
                    showErrors={false}
                  />
                </div>
                {!dob ? (
                  <small className="error">
                    <ErrorMessage errors={errors} name="dob" />
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 py-2">
              <div className="user-number">
                <span className="mobile-no">
                  {userProfile.mobile_number}&nbsp;
                  <i
                    className="icon-information"
                    onMouseOver={() => onTomTooltip()}
                    onMouseOut={() => onCloseTooltip()}
                  ></i>
                  <div className="tooltip-tom" ref={tooltip_edit_phone_number}>
                    If you need to edit your <br /> number, please contact{" "}
                    <br />
                    <span className="text-primary">Syiok! Careline.</span>
                  </div>
                </span>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please provide a valid email address",
                    },
                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "*Please provide a valid email address",
                    },
                  })}
                  id="email"
                  className="tom-input"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  defaultValue={userProfile.email}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="email" />
                </small>
              </div>
            </div>
            {userProfile.auth_provider ? (
              ""
            ) : (
              <Fragment>
                <div className="offset-1 offset-md-2 col-10 col-md-8">
                  <div className="input-control">
                    <input
                      type="password"
                      ref={register({
                        required: {
                          value: watchPasssword == "" ? false : true,
                          message: "Current password is required",
                        },
                      })}
                      id="old_password"
                      className="tom-input"
                      name="old_password"
                      placeholder="Current password"
                      autoComplete="off"
                    />
                    <span
                      className="icon-eye"
                      onClick={(e) => {
                        togglePassword(e, "old_password");
                      }}
                    ></span>
                    <small className="error">
                      <ErrorMessage errors={errors} name="old_password" />
                    </small>
                    <p>
                      <i>
                        Please ensure your password includes min. 8 characters
                        (min. 1 uppercase character, 1 lowercase character, 1
                        number and 1 special character).
                      </i>
                    </p>
                  </div>
                </div>
                <div className="offset-1 offset-md-2 col-10 col-md-8">
                  <div className="input-control">
                    <input
                      type="password"
                      ref={register({
                        pattern: {
                          value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>_~`+-]).{8,}$/,
                          message:
                            "*Please ensure your password includes min. 8 characters (min. 1 uppercase character, 1 lowercase character, 1 number and 1 special character).",
                        },
                      })}
                      id="password"
                      className="tom-input"
                      name="password"
                      placeholder="New password"
                      autoComplete="off"
                    />
                    <span
                      className="icon-eye"
                      onClick={(e) => {
                        togglePassword(e, "password");
                      }}
                    ></span>
                    <small className="error">
                      <ErrorMessage errors={errors} name="password" />
                    </small>
                  </div>
                </div>
                <div className="offset-1 offset-md-2 col-10 col-md-8">
                  <div className="input-control">
                    <input
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === watchPasssword ||
                          "Password doesn't match. Please enter password again",
                      })}
                      id="password_confirm"
                      className="tom-input"
                      name="password_confirm"
                      placeholder="Confirm password"
                      autoComplete="off"
                    />
                    <span
                      className="icon-eye"
                      onClick={(e) => {
                        togglePassword(e, "password_confirm");
                      }}
                    ></span>
                    <small className="error">
                      <ErrorMessage errors={errors} name="password_confirm" />
                    </small>
                  </div>
                </div>
              </Fragment>
            )}
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Address"),
                    },
                    minLength: {
                      value: 8,
                      message: minLengthMessage("Address", 8),
                    },
                  })}
                  id="address"
                  className="tom-input"
                  name="address"
                  placeholder="Address"
                  autoComplete="off"
                  defaultValue={userProfile.address}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="address" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("State"),
                    },
                    minLength: {
                      value: 4,
                      message: minLengthMessage("State", 4),
                    },
                  })}
                  id="state"
                  className="tom-input"
                  name="state"
                  placeholder="State"
                  autoComplete="off"
                  maxLength="20"
                  defaultValue={userProfile.state}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="state" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Country"),
                    },
                    minLength: {
                      value: 8,
                      message: minLengthMessage("Country", 8),
                    },
                  })}
                  id="country"
                  className="tom-input"
                  name="country"
                  placeholder="Country"
                  autoComplete="off"
                  maxLength="20"
                  defaultValue={userProfile.country}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="country" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
              <div className="input-control">
                <input
                  type="number"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Postal Code"),
                    },
                    maxLength: {
                      value: 5,
                      message: maxLengthMessage("Postal Code", 5),
                    },
                  })}
                  inputMode="decimal"
                  className="tom-input"
                  name="postal_code"
                  placeholder="Postal Code"
                  autoComplete="off"
                  maxLength="5"
                  defaultValue={userProfile.postal_code}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="postal_code" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 text-center text-md-right">
              <button
                className="tom-button primary-button w-100"
                disabled={isLoading}
              >
                Save Profile
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 text-center text-md-right">
              <button
                className="tom-button default-button w-100"
                onClick={(e) => history.push("/loyalty")}
              >
                Back To Dashboard
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}