import React, { Fragment, useEffect, useRef, useState } from "react";
import Swiper from "react-id-swiper";
import "./product-slider.scss";
import "swiper/swiper.scss";

export default function OurProductsComponent(props) {
  return (
    <div className="swiper">
      <Swiper
        {...props.params}
      >
        {props.products.map((x, index) => {
          return (
            <div key={index}>
              <img src={x.img} alt={x.alt} width="100%" />
              <p><b>
                {x.content.map((y, index) => {
                  return <Fragment key={index}>{y} <br /></Fragment>;
                })}
              </b></p>
            </div>
          );
        })}
      </Swiper>
    </div>
  );
}
