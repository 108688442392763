import React, { useContext, useEffect, useRef, useState } from "react";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import StepBarComponent from "../../step-bar-component/step-bar-component";
import { fullOilInfo, semiOilInfo } from "../../../../../../data/oil_info";
import "./product-choose.scss";
import { PopupContext } from "../../../../../../provider/popup-provider";
import {
  oilRanking,
  freeOilRangeList,
} from "../../../../../../helpers/oil-ranking";

export default function ProductChooseComponent(props) {
  const { service, setService } = useContext(AddServiceContext);
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const [free_oil_range, setFreeOilRange] = useState([]);
  const [init, setInit] = useState(false);
  const topSectionRef = useRef(null);

  useEffect(() => {
    onScrollTop()
    if (!init) {
      initialTab();
      setInit(true);
    }
  }, [free_oil_range]);

  const onScrollTop = () => {
    var headerOffset = 45;
    const elementPosition = topSectionRef.current.getBoundingClientRect().top
    window.scrollTo({
      behavior: "smooth",
      top: elementPosition + window.pageYOffset - headerOffset
    })
  }

  const initialTab = () => {
    if (service.free_oil && free_oil_range.length == 0) {
      onProductChoose(service.free_oil, "");
      setFreeOilRange(freeOilRangeList(service.current_oils));
    }
    const tab_1 = document.querySelector("#tab-content-1");
    const tab_2 = document.querySelector("#tab-content-2");
    if (service.tab_oil === "tab-2") {
      document.getElementById("tab-2").classList.add("active");
      tab_2.classList.add("active");
      tab_2.style.display = "block";
      document.getElementById("tab-1").classList.remove("active");
      tab_1.classList.remove("active");
      tab_1.style.display = "none";
    } else {
      document.getElementById("tab-1").classList.add("active");
      tab_1.classList.add("active");
      tab_1.style.display = "block";
      document.getElementById("tab-2").classList.remove("active");
      tab_2.classList.remove("active");
      tab_2.style.display = "none";
    }
  };

  const onProductTabSelect = (e) => {
    let tab_target = document.getElementById(`tab-${e}`);

    if (e === 1) {
      if (
        document
          .getElementById("tab-content-1")
          .contains(document.querySelector("#tab-content-1 .option-box"))
      ) {
        tab_target.classList.add("active");
        document.getElementById("tab-2").classList.remove("active");
        document.getElementById("tab-content-1").style.display = "block";
        document.getElementById("tab-content-2").style.display = "none";
      }
    } else if (e === 2) {
      if (
        document
          .getElementById("tab-content-2")
          .contains(document.querySelector("#tab-content-2 .option-box"))
      ) {
        tab_target.classList.add("active");
        document.getElementById("tab-1").classList.remove("active");
        document.getElementById("tab-content-1").style.display = "none";
        document.getElementById("tab-content-2").style.display = "block";
      }
    }
  };

  const onProductChoose = (oilcode, tab) => {
    let openDropPopup = false;
    let serviceInfo = Object.assign({}, service);
    // if change from 3 to 4 indicate popup
    if (serviceInfo.cycle_oil_type == 3) {
      if (tab === "tab-2") {
        const popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "danger-icon";
        popupVal.content = [
          "You have selected a different engine oil from your previous service. If you wish to proceed, your free bottle will be the lowest oil variant.",
        ];
        popupVal.buttons = [
          {
            type: "primary",
            text: "Close",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        openDropPopup = true;
      }
    }

    if (
      !openDropPopup &&
      serviceInfo.cycle_guid !== "" &&
      !serviceInfo.free_oil
    ) {
      var currentOil = serviceInfo.oil.ori;
      var downOil = oilRanking(currentOil, oilcode);
      if (downOil) {
        const popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "danger-icon";
        popupVal.content = [
          "You have selected a different engine oil from your previous service. If you wish to proceed, your free bottle will be the lowest oil variant.",
        ];
        popupVal.buttons = [
          {
            type: "primary",
            text: "Close",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
      }
    }

    serviceInfo.tab_oil = tab;
    serviceInfo.oil.float = oilcode;
    setService(serviceInfo);
  };

  return (
    <section ref={topSectionRef} className="product-choose-component">
      <div className="step-section">
        <div className="container">
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
              <h2 className="text-center mb-4">STEP 2</h2>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
              <StepBarComponent active="2"></StepBarComponent>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
              <p className="text-center page-description">
                Please select your oil variant and quantity
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-selection">
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-12 col-md-8 mb-4 px-4 px-md-0">
              <div className="row tab-bar mx-2">
                <div
                  className="offset-1 col-5 py-3 px-0 tab"
                  id="tab-1"
                  onClick={() => onProductTabSelect(1)}
                >
                  <div>
                    <p className="mb-0 pl-md-5">
                      <b>Buy 3 get 1 free</b>
                    </p>
                  </div>
                </div>
                <div
                  className="col-5 py-3 px-0 tab"
                  id="tab-2"
                  onClick={() => onProductTabSelect(2)}
                >
                  <div>
                    <p className="mb-0 pl-md-5">
                      <b>Buy 4 get 1 free</b>
                    </p>
                  </div>
                </div>
              </div>
              <div id="tab-content-1">
                <div className="row px-4 py-4">
                  {fullOilInfo.map(function (oil, index) {
                    if (service.free_oil !== "") {
                      return free_oil_range.map((free_oil) => {
                        if (free_oil === oil.code) {
                          return (
                            <div
                              key={index}
                              className={`col-6 col-md-3 py-2 mb-2 option-box ${
                                service.oil.float === oil.code ? "active" : ""
                              }`}
                              onClick={() => onProductChoose(oil.code, "tab-1")}
                            >
                              <img
                                src={`/assets/images/${oil.code}.png`}
                                width="100%"
                                className="mb-3"
                              />
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: oil.name.replace(
                                    new RegExp("\r?\n", "g"),
                                    "<br />"
                                  ),
                                }}
                              ></p>
                            </div>
                          );
                        }
                      });
                    } else {
                      return (
                        <div
                          key={index}
                          className={`col-6 col-md-3 py-2 mb-2 option-box ${
                            service.oil.float === oil.code ? "active" : ""
                          }`}
                          onClick={() => onProductChoose(oil.code, "tab-1")}
                        >
                          <img
                            src={`/assets/images/${oil.code}.png`}
                            width="100%"
                            className="mb-3"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: oil.name.replace(
                                new RegExp("\r?\n", "g"),
                                "<br />"
                              ),
                            }}
                          ></p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div id="tab-content-2">
                <div className="row px-4 py-4">
                  {semiOilInfo.map(function (oil, index) {
                    if (service.free_oil !== "") {
                      return free_oil_range.map((free_oil) => {
                        if (free_oil === oil.code) {
                          return (
                            <div
                              key={index}
                              className={`col-6 col-md-3 py-2 mb-2 option-box ${
                                service.oil.float === oil.code ? "active" : ""
                              }`}
                              onClick={() => onProductChoose(oil.code, "tab-2")}
                            >
                              <img
                                src={`/assets/images/${oil.code}.png`}
                                width="100%"
                                className="mb-3"
                              />
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: oil.name.replace(
                                    new RegExp("\r?\n", "g"),
                                    "<br />"
                                  ),
                                }}
                              ></p>
                            </div>
                          );
                        }
                      });
                    } else {
                      return (
                        <div
                          key={index}
                          className={`col-6 col-md-3 py-2 mb-2 option-box ${
                            service.oil.float === oil.code ? "active" : ""
                          }`}
                          onClick={() => onProductChoose(oil.code, "tab-2")}
                        >
                          <img
                            src={`/assets/images/${oil.code}.png`}
                            width="100%"
                            className="mb-3"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: oil.name.replace(
                                new RegExp("\r?\n", "g"),
                                "<br />"
                              ),
                            }}
                          ></p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
