import React from "react";
import "./footer.scss";

export default function FooterComponent(props) {
  let year = new Date().getFullYear();
  const openSocialUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <section className="footer-component">
      <div className="row footer-content">
        <div className="col-12 py-1 px-5">
          <div className="text-center">
            <span onClick={() => openSocialUrl("https://www.totalenergies.my/")}>
              <i className="icon-www pr-1"></i>
            </span>
            <span
              onClick={() => openSocialUrl(
                "https://www.facebook.com/TotalEnergiesMalaysia/ "
              )}
            >
              <i className="icon-facebook-circular-logo pl-1"></i>
            </span>
            <span
              onClick={() => openSocialUrl(
                "https://www.instagram.com/totalenergies_my/"
              )}
            >
                <svg fill="#FFF" className="icon-instagram" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="#FFF">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M20.445 5h-8.891A6.559 6.559 0 0 0 5 11.554v8.891A6.559 6.559 0 0 0 11.554 27h8.891a6.56 6.56 0 0 0 6.554-6.555v-8.891A6.557 6.557 0 0 0 20.445 5zm4.342 15.445a4.343 4.343 0 0 1-4.342 4.342h-8.891a4.341 4.341 0 0 1-4.341-4.342v-8.891a4.34 4.34 0 0 1 4.341-4.341h8.891a4.342 4.342 0 0 1 4.341 4.341l.001 8.891z"></path><path d="M16 10.312c-3.138 0-5.688 2.551-5.688 5.688s2.551 5.688 5.688 5.688 5.688-2.551 5.688-5.688-2.55-5.688-5.688-5.688zm0 9.163a3.475 3.475 0 1 1-.001-6.95 3.475 3.475 0 0 1 .001 6.95zM21.7 8.991a1.363 1.363 0 1 1-1.364 1.364c0-.752.51-1.364 1.364-1.364z"></path></g></svg>
            </span>
          </div>
          <hr />
          <p className="text-center mb-0">
            <a href={'/privacy-policy'}>Cookies &amp; Privacy</a> &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; <a href="https://www.totalenergies.my/legal-notices" target="_blank">Legal</a>
          </p>
            <p className="text-center pt-3">TotalEnergies Marketing Malaysia Sdn Bhd<br/>
(Formerly known as Total Oil Malaysia Sdn Bhd) 199401041979 (327667-U) - {year}</p>
        </div>
      </div>
    </section>
  );
}
