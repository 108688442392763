import React from "react";
import ServiceStatus from "../../../../../share-component/service-status";
import "./service-info.scss";

export default function ServiceInfoComponent(props) {

  return (
    <section className="service-info-component">
      <div className="container">
        <div className="row mb-4">
          <div className="offset-0 offset-md-0 col-12 col-md-12 mb-4 mb-md-5">
            <h2 className="title mb-0">Service Cycle</h2>
          </div>
          <div className="offset-0 offset-md-2 col-12 col-md-8">
              <ServiceStatus vehicle={props.vehicle}></ServiceStatus>
          </div>
        </div>
      </div>
    </section >
  );
}
