import React from "react";
import TopSectionScreen from "../top-section/top-section";
import ContentSection from "./content-section/content-section";
import "./how-it-works.scss";

export default function HowItWorksScreen(props) {
    return (
        <div className="how-it-work-screen">
            <TopSectionScreen title="How To Join"></TopSectionScreen>
            <ContentSection></ContentSection>
        </div>
    );
}
