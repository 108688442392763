import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./user-option-section.scss";
import ReactPixel from 'react-facebook-pixel';
import TagManager from "react-gtm-module";

export default function UserOptionSectionComponent(props) {
  const history = useHistory();

  useEffect(() => {
    const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };

    ReactPixel.init(1115328369330456, advancedMatching, options);
    ReactPixel.trackCustom('LoyaltyPageView');
    
    const tagManagerArgs = {
        gtmId: 'GTM-P253LGN'
    }
    TagManager.initialize(tagManagerArgs);
  }, []);

  function LoyaltyPrecisionChallangeButtonFBPixel() {
    ReactPixel.trackCustom('LoyaltyPrecisionChallangeButton');
    setTimeout(() => {
      window.open('/contest?utm_source=Syiok+Website&utm_medium=LoyaltyDashboardPage&utm_campaign=ThePrecisionChallenge', '_self');
    }, 500);
  }

  return (
    <section className="user-option-section-component">
      <div className="container">
        <div className="row px-md-4 text-center text-md-left">
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/loyalty/add-new-vehicle")}>
              <img src="/assets/images/add-car.png" alt="Add a vehicle" width="100%" />
              <p className="text-responsive text-xs mb-0">Add a <br className="d-none d-md-block" /> vehicle</p>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/workshops-locator")}>
              <img src="/assets/images/location.png" alt="Workshop Locator" width="100%" />
              <p className="text-responsive text-xs mb-0">Workshop <br className="d-none d-md-block" /> Locator</p>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="option-box" onClick={() => history.push("/refer-and-earn")}>
              <img src="/assets/images/refer-earn.png" alt="Refer & earn" width="100%" />
              <p className="text-responsive text-xs mb-0">Refer <br className="d-none d-md-block" /> &amp; earn</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
