import React, { useEffect } from "react";
import WelcomeComponent from "../../welcome/welcome";
import SendOtpFormComponent from "./send-otp-form/send-otp-form";
import "./send-otp-screen.scss";
import PopupContextProvider from "../../../../provider/popup-provider";
import { useHistory } from "react-router-dom";

export default function SendOtpScreen(props) {
  const history = useHistory();

  useEffect(() => {
    getRegisterMobileNumber();
  }, []);

  const getRegisterMobileNumber = () => {
    if (props.history.location.state === undefined) {
      history.replace("/register");
    } else {
      const register_phone = props.history.location.state.register_phone;
      document.getElementById("mobile-no").innerText =
        "+" + register_phone + ".";
    }
  };

  return (
    <div className="send-otp-screen">
      <PopupContextProvider>
        <WelcomeComponent></WelcomeComponent>
        <SendOtpFormComponent
          register_phone={props.history.location.state?.register_phone}
        ></SendOtpFormComponent>
      </PopupContextProvider>
    </div>
  );
}
