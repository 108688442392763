import React from "react";
import { Link } from "react-router-dom";
import "./content-section.scss";

export default function ContentSection(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return (
    <section className="content-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2>
              <span style={{textTransform: "capitalize"}}>Quartz Syiok!</span> members get a free
              engine oil!
            </h2>
            <p>
              Service your car with Quartz engine oil and we’ll reward you
              with a complimentary bottle! All you have to do is purchase 3 or 4
              selected Quartz products and service your car at our{" "}
              <Link to={"/workshops-locator"} className="text-primary">
                workshops
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h2>Here’s how it works:</h2>
          </div>
        </div>
        <div className="row text-center d-none d-md-flex mb-5">
          <div className="col-md-3">
            <img
              src="/assets/images/register-vip-rewards.png"
              alt="Register for Syiok! Loyalty Programme"
              width="100%"
            />
            <p className="mb-0">
            <Link to={urlParams.get('ref') ? `/register?ref=${urlParams.get('ref')}` : "/register"}>Register</Link> for <br />{" "}
              <b>Quartz Syiok!</b>
            </p>
          </div>
          <div className="col-md-3">
            <img
              src="/assets/images/add-vehicles.png"
              alt="Add vehicle"
              width="100%"
            />
            <p className="mb-0">Add vehicle(s)</p>
          </div>
          <div className="col-md-3">
            <img
              src="/assets/images/red-bottle.png"
              alt="Submit 3/4 services"
              width="100%"
            />
            <p className="mb-0">Submit 3/4 services</p>
          </div>
          <div className="col-md-3">
            <img
              src="/assets/images/icon-smiley.png"
              alt="Redeem your free oil"
              width="100%"
            />
            <p className="mb-0">Redeem your free oil!</p>
          </div>
        </div>
        <div className="d-md-none">
          <div className="row content">
            <div className="col-4">
              <img
                src="/assets/images/register-vip-rewards.png"
                alt="Register for Syiok! Loyalty Programme"
                width="100%"
              />
            </div>
            <div className="col-8 my-auto">
              <p className="mb-0">
                <Link to={urlParams.get('ref') ? `/register?ref=${urlParams.get('ref')}` : "/register"}>Register</Link> for <br />{" "}
                <b>Syiok!</b>
              </p>
            </div>
          </div>
          <div className="row content">
            <div className="col-4">
              <img
                src="/assets/images/add-vehicles.png"
                alt="Add vehicle"
                width="100%"
              />
            </div>
            <div className="col-8 my-auto">
              <p className="mb-0">Add vehicle(s)</p>
            </div>
          </div>
          <div className="row content">
            <div className="col-4">
              <img
                src="/assets/images/red-bottle.png"
                alt="Submit 3/4 services"
                width="100%"
              />
            </div>
            <div className="col-8 my-auto">
              <p className="mb-0">Submit 3/4 services</p>
            </div>
          </div>
          <div className="row content">
            <div className="col-4">
              <img
                src="/assets/images/icon-smiley.png"
                alt="Redeem your free oil"
                width="100%"
              />
            </div>
            <div className="col-8 my-auto">
              <p className="mb-0">Redeem your free oil!</p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p>
              Be sure to complete your service cycle within 3 years to get your
              free oil.
            </p>
            <p>
              For more information, see our{" "}
              <Link to="/faq" className="text-primary">
                FAQ
              </Link>{" "}
              or contact{" "}
              <Link to="/contact-support" className="text-primary">
                Quartz Syiok! Careline
              </Link>
              .{" "}
            </p>
            <p>
              Have you read the Quartz Syiok! Terms &amp;
              Conditions? If you haven’t,{" "}
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/assets/pdf/Quartz Syiok! Loyalty Programme_T&C_25052023.pdf"
                }
                target="_blank"
                className="text-primary"
              >
                click here
              </a>
              .
            </p>
            <p>
              You may download a copy of our{" "}
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/assets/pdf/Total_Syiok_Members_Manual_Digital.pdf"
                }
                target="_blank"
                className="text-primary"
              >
                consumer manual guide
              </a>{" "}
              for step-by-step guidance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
