import React from "react";
import "./about-us.scss";
import TopSectionScreen from "../top-section/top-section";
import ContentComponent from "./content-component/content-component";

export default function AboutUsScreen(props) {
    return (
        <div className="about-us-screen">
            <TopSectionScreen title="About Us"></TopSectionScreen>
            <ContentComponent></ContentComponent>
        </div>
        
    );
}
