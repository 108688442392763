import axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../../config/config";

export const registrationAPI = async (
  fullname,
  birth_date,
  email,
  mobile_number,
  password,
  confirm_password,
  referrer_code
) => {
  try {
    const response = await axios.post(
      `${apiURL}/api/user`,
      querystring.stringify({
        fullname,
        email,
        mobile_number,
        birth_date,
        password,
        confirm_password,
        referrer_code
      })
    );

    // check for status code
    return response.data.status_code;
  } catch (error) {
    return 502;
  }
};

export const socialRegistrationAPI = async (
  fullname,
  birth_date,
  email,
  mobile_number,
  provider,
  user_id,
  access_token,
  token_id
) => {
  try {
    const response = await axios.post(
      "http://localhost:3000/api/user/register/social",
      querystring.stringify({
        fullname,
        birth_date,
        email,
        mobile_number,
        provider,
        user_id,
        access_token,
        token_id,
      })
    );
    // check for status code
    return response.data.status_code;
  } catch (error) {
    return 502;
  }
};