import React, { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./forgot-password-form.scss";
import axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../config/config";
import { validation_message } from "../../../../data/validation_message";
import { PopupContext } from "../../../../provider/popup-provider";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../../../config/config";

export default function ForgotPasswordFormComponent(props) {
  const history = useHistory();
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isLoading) {
      const mobile_ext_no = data.mobile_ext_no;
      const mobile_no = data.mobile_no;
      let register_phone = `${mobile_ext_no}${mobile_no}`;

      try {
        let url = `${apiURL}/api/user/password/forgot`;
        let requestBody = { mobile_number: register_phone };
        let config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await axios.post(
          url,
          querystring.stringify(requestBody),
          config
        );

        // check for status code
        if (response.data.status_code == 200) {
          history.push({
            pathname: "/forgot-password/tac-number",
            state: {
              register_phone: data.mobile_ext_no + data.mobile_no,
              reset_token: response.data.result,
            },
          });
        } else {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = [response.data.error + "."];
          popupVal.img = "danger-icon";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "CLOSE",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      }
    }
  };

  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    document.getElementById("recaptcha").checked = true;
  };

  return (
    <section className="forgot-password-form-component">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="mb-0 title">
              Password Reset
            </h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-md-5 mb-4">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mt-md-3 mb-md-3 px-md-0">
              <div className="mobile-no-input">
                <div className="input-control">
                  <select
                    id="mobile-ext-no"
                    ref={register}
                    className="tom-input"
                    name="mobile_ext_no"
                    onChange={() => {
                      document.getElementById("mobile-no").value = "";
                    }}
                  >
                    <option value="60">MY: +60</option>
                    <option value="65">SG: +65</option>
                    <option value="65">BN: +673</option>
                    <option value="65">TH: +66</option>
                  </select>
                  <input
                    type="number"
                    ref={register({
                      required: {
                        value: true,
                        message: `*${validation_message.forgot_password.mobile_no.require.message}`,
                      },
                      minLength: {
                        value: 7,
                        message: `*${validation_message.forgot_password.mobile_no.minLength.message}`,
                      },
                      maxLength: {
                        value: 13,
                        message: `*${validation_message.forgot_password.mobile_no.maxLength.message}`,
                      },
                      pattern: {
                        value: /^[0-9]{7,13}$/,
                        message: `*${validation_message.forgot_password.mobile_no.pattern.message}`,
                      },
                    })}
                    id="mobile-no"
                    name="mobile_no"
                    inputMode="decimal"
                    className="tom-input"
                    placeholder="Mobile No."
                    onChange={(e) => {
                      if (
                        document.getElementById("mobile-ext-no").value == 60 &&
                        e.target.value.charAt(0) === "0"
                      ) {
                        return (e.target.value = e.target.value.slice(1));
                      }
                      if (e.target.value.toString().length > 13) {
                        return (e.target.value = e.target.value.substr(0, 13));
                      }
                    }}
                  />
                  <small className="error">
                    <ErrorMessage errors={errors} name="mobile_no" />
                  </small>
                </div>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mt-3">
              <div className="input-control">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey.sitekey}
                  onChange={onChangeRecaptcha}
                />
                <input
                  type="checkbox"
                  ref={register({
                    required: {
                      value: true,
                      message: `*Validation error`,
                    },
                  })}
                  id="recaptcha"
                  name="recaptcha"
                  className="d-none"
                />
                <small className="error mt-1">
                  <ErrorMessage errors={errors} name="recaptcha" />
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-md-0">
              <button
                className="tom-button primary-button"
                disabled={isLoading}
              >
                Reset Password
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}