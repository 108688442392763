import React from "react";
import "./programme-date.scss";
import moment from "moment";
import { formatDate } from "../../../../../helpers/moment-helper";

export default function ProgrammeDateComponent(props) {

  let start_date = props.start_date.Valid ? formatDate(props.start_date.Time) : "N/A";
  let expiry_date = props.expiry_date.Valid ? formatDate(props.expiry_date.Time) : "N/A";

  return (
    <section className="programme-date-component">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-6 px-0 pr-md-1 text-right">
            <p>Programme started:</p>
            <p className="mb-0">Programme expiration:</p>
          </div>
          <div className="col-5 col-md-6 offset-1 offset-md-0 px-0 pl-md-1">
            <p>
              <b>{start_date}</b>
            </p>
            <p className="mb-0">
              <b>{expiry_date}</b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
