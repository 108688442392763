import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import PageNotFoundScreen from "./404-screen/404-screen";
import NavbarComponent from "../global-component/navbar/navbar";
import PopupContextProvider from "../../provider/popup-provider";
import NavActiveContextProvider from "../../provider/navbar-provider";
import InboxContextProvider from "../../provider/inbox-provider";
import UserContextProvider from "../../provider/user-provider";
import SideNavigationComponent from "../global-component/side-navigation/side-navigation";
import { dashboard_routes, login_routes } from "../../data/route-list";

const PageNotFoundTemplate = () => {
  const history = useHistory();

  useEffect(() => {
    if (Cookie.get("access_token") != null) {
      if (
        login_routes.find((x) => x.path === window.location.pathname)?.path !==
        undefined
      ) {
        history.replace("/loyalty");
      }
    } else {
      if (
        dashboard_routes.find((x) => x.path === window.location.pathname)
          ?.path !== undefined
      ) {
        history.replace("/login");
      }
    }
  }, []);

  return (
    <PopupContextProvider>
      <NavActiveContextProvider current_page={""}>
        <UserContextProvider>
          <InboxContextProvider>
            <NavbarComponent></NavbarComponent>
            <PageNotFoundScreen></PageNotFoundScreen>
            <SideNavigationComponent></SideNavigationComponent>
          </InboxContextProvider>
        </UserContextProvider>
      </NavActiveContextProvider>
    </PopupContextProvider>
  );
}

export default PageNotFoundTemplate;