import React from "react";
import { downloadPdf } from "../../../../helpers/downloadPDF";
import "./content-section.scss";
import { fullOilInfo } from "../../../../data/oil_info";
import { Fragment } from "react";

export default function ContentSection(props) {
  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className="content-section">
      <div className="container pt-5">
        <div className="row mt-md-4 mb-md-4 justify-content-center">
          <div className="col-12 col-md-7">
            <div className="card card-main">
              <div className="row">
                <div className="col-8 pl-md-5">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <h2 className="product-category">
                      BUY 3 GET 1 FREE
                    </h2>
                  </div>
                </div>
                <div className="col-4">
                  <div className="product-brand">
                    <img
                      src="/assets/images/fully_syntetic_main.png"
                      alt="Buy 3 get 1 free"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row product-list">
          {fullOilInfo.map((x) => {
            return (
              <div className="col-6 col-md-3">
                <div className="card card-product-detail">
                  <img
                    src={`/assets/images/${x.code}.png`}
                    alt={x.desc}
                    width="100%"
                  />
                  <div className="d-flex flex-column justify-content-between h-100">
                    <p>
                      <b>
                        {x.full_name.map((y, index) => {
                          return (
                            <Fragment>
                              {y}
                              {x.full_name.length > 1 &&
                              index + 1 < x.full_name.length ? (
                                <>
                                  <br />{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          );
                        })}
                      </b>{" "}
                      <br />
                      {x.desc}
                    </p>
                    <div>
                      <button
                        className="tom-button primary-button mb-3"
                        onClick={() => downloadPdf(x.pdf_name)}
                      >
                        DOWNLOAD TECH. DATA <i className="icon-file"></i>
                      </button>
                      <button
                        className="tom-button default-button"
                        onClick={() => openUrl(x.read_more_url)}
                      >
                        <span className="d-flex justify-content-center">
                          <span>READ MORE</span> &nbsp; <div className="arrow-right"></div>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
