import React from "react";
import TopSectionScreen from "../top-section/top-section";
import { Link, useHistory } from "react-router-dom";
import "./faq.scss";
// import ContentSection from "./content-section/content-section";

export default function FaqScreen(props) {
  const history = useHistory();

  const openDescription = (e) => {
    if (!e.target.parentElement.parentElement.classList.contains("active")) {
      let information_box = document.querySelectorAll(
        ".information-list .information-box"
      );
      information_box.forEach((x) =>
        x.classList.contains("active") ? x.classList.remove("active") : ""
      );
      e.target.parentElement.parentElement.classList.add("active");
    } else {
      e.target.parentElement.parentElement.classList.remove("active");
    }
  };

  return (
    <div className="faq-screen">
      <TopSectionScreen title="FAQ"></TopSectionScreen>
      <section className="content-section">
        <div className="container">
          <div className="row px-3 px-md-0">
            <div className="col-12 offset-md-2 col-md-8">
              <div className="information-list">
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What is Quartz Syiok! and what are the
                      benefits of joining this loyalty programme?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      With Quartz Syiok!, you can enjoy:
                      <ul className="dash">
                        <li>
                          Buy 3, free 1 QUARTZ 9000, QUARTZ INEO, or
                        </li>
                        <li>
                          Buy 4, free 1 QUARTZ 7000, QUARTZ 8000
                        </li>
                        <li>
                          Limited edition TotalEnergies merchandise when you get
                          your friend to service their car with Quartz
                          engine oil at any participating workshops.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What are the criteria to join this loyalty programme?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You must be a car owner with a valid driving licence and
                      above 18 years old of age. Quartz Syiok!
                      is only available to private car drivers of personal use
                      only.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What is a verification code?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      A verification code is a six (6) alphanumerical code that
                      is sent to user's mobile number upon registration.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I did not receive a verification code?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Please ensure that the mobile number registered for Quartz Syiok! is a valid mobile number. Kindly
                      get in touch with Quartz Syiok! Careline: 012-6782283
                      (Monday to Sunday, 9am-5pm) to retrieve the verification
                      code if you did not receive it upon registration.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I need to change my mobile number?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Please contact Quartz Syiok! Careline at 012-6782283
                      (Monday to Sunday, 9am-5pm) to change or edit the
                      registered mobile number.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Is Quartz Syiok! available nationwide?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Quartz Syiok! is open to <Link to="/workshops-locator" className="text-primary">participating workshops</Link>{" "}
                      in Peninsular Malaysia and is not eligible for the workshops 
                      in East Malaysia (Sabah and/or Sarawak).
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      How long is the validity of this loyalty programme?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Quartz Syiok! is valid for 36 months,
                      starting from the first service submission.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Which engine oil is available in this loyalty programme??
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      The engine oil available within Quartz Syiok! include:
                      <ol>
                        <li>
                          Buy 3, free 1:
                          <ul>
                            <li>QUARTZ 9000</li>
                            <li>QUARTZ INEO</li>
                          </ul>
                        </li>
                        <li>
                          Buy 4, free 1:
                          <ul>
                            <li>QUARTZ 7000</li>
                            <li>QUARTZ 8000</li>
                          </ul>
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      How often should I service my car?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      In order to enjoy the rewards through this programme
                      during the service cycle, we suggest you to service your
                      vehicle as follow:
                      <ul>
                        <li>
                          3+1 (fully synthetic range): 90 days or 7,000KM, whichever comes first
                        </li>
                        <li>
                          4+ 1 (part synthetic range): 45 days or 5,000KM,
                          whichever comes first
                        </li>
                      </ul>
                      Please note that the interval between the oil changes must
                      be more than 30 days.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Where can I service my car(s) in order to join this
                      loyalty programme?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Car(s) can be serviced at any participating workshops. 
                      Refer to the location of the workshops here:{" "}
                      <Link to={"/workshops-locator"} className="text-primary">
                        https://syiok.totalenergies.my/workshops-locator
                      </Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Which engine oil will I be able to redeem for free?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You may redeem one (1) free engine oil according to the
                      range you purchase during the service cycle, which are:
                      <ol>
                        <li>
                          Buy 3, free 1:
                          <ul>
                            <li>QUARTZ 9000</li>
                            <li>QUARTZ INEO</li>
                          </ul>
                        </li>
                        <li>
                          Buy 4, free 1:
                          <ul>
                            <li>QUARTZ 7000</li>
                            <li>QUARTZ 8000</li>
                          </ul>
                        </li>
                      </ol>
                      Please note that should you switch to a lower range during
                      the mid-cycle, you will be honored with a lower range
                      engine oil amongst your previous selected variant.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I service my car(s) in workshops that are not part
                      of the participating workshops?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      To be eligible for Quartz Syiok!, your
                      vehicle(s) must be serviced at any participating workshops
                      only. Refer to the location of the workshops
                      here:{" "}
                      <Link to={"/workshops-locator"} className="text-primary">
                        https://syiok.totalenergies.my/workshops-locator
                      </Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I change the engine oil that I used within my service cycle?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                    Yes, you can. However, kindly note that the free oil rewarded at
                    the end of your service cycle will be of the lowest value amongst
                    all the purchased engine oils during your entire service cycle.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      If my service cycle has exceeded 36 months, can I still
                      redeem my free bottle?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      No, you may not. To redeem a free bottle, the service
                      cycle must be completed within 36 months from the first
                      service date.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I change my workshop within a service cycle?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Yes, you can. However, it is not recommended as you will
                      start a new service cycle in another workshop.{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I bring my own engine oil for the service?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You will not be eligible for the loyalty programme if you
                      bring your own Quartz engine oil.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I redeem the free bottle with other engine oil or
                      exchange it for cash?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      No. The free bottle cannot be substituted with any other
                      engine oil or exchanged for cash.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      How can I submit a service request?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      To submit a service request, please follow these steps:
                      <ol>
                        <li>
                          Register as a Quartz Syiok! member
                        </li>
                        <li>Add car</li>
                        <li>Select the car being serviced</li>
                        <li>Insert workshop ID &amp; mileage </li>
                        <li>Select engine oil </li>
                        <li>Upload receipt </li>
                        <li>Verify service with workshop</li>
                      </ol>
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What is a Service ID?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Upon successful service submission, you will receive your
                      service summary together with a QR code and a Service ID.
                      The Service ID is an alphanumeric code below the QR code.
                      Service ID may be used when the camera of your mechanic's
                      mobile device is not functioning.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What is the difference between Service ID and QR Code?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Both Service ID and QR Code serve the same function: for
                      your mechanic to use to verify your service for Quartz Syiok!.
                      The difference, however, is that
                      the Service ID is an alphanumeric code, while the QR Code
                      is a graphical code that can be scanned with your
                      mechanic's mobile device. The QR Code is the recommended
                      method, as it is more convenient. However, in the event
                      that the camera of your mechanic's mobile device is not
                      functioning, they may choose to verify the service by
                      keying in the Service ID.{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if the workshop is busy and is unable to verify my
                      service request on the spot?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      The service can be verified later. However, please note
                      that your workshop will need to verify the service within
                      five (5) days.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      How long will my service request be valid?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      A service request is valid for 5 days upon submission. If
                      the participating workshop fails to verify the
                      service within 5 days, you may contact Quartz Syiok!
                      Careline at 012-6782283 (Monday to Sunday, 9am–5pm) for
                      support.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if the workshop rejects my service request?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You will be notified with the reason of rejection if your
                      service request has been rejected by the workshop. You may
                      submit a new service request again and present the QR
                      code/ Service ID to your workshop for verification
                      purpose.{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I submitted the wrong information?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Wrong information may result in cancellation of service
                      request by the workshop. Member will have to submit a new
                      service request.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I can't connect to the internet when submitting my
                      service request?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Service request must be submitted online. You may need to
                      resubmit your service request once you are connected to
                      the Wi-Fi or your mobile data.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if my service request has been verified but I need to
                      change the information?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Kindly contact Quartz Syiok! Careline at 012-6782283
                      (Monday to Sunday, 9am–5pm) for further assistance.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I need to edit my car information?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You may edit your information at the 'My profile' section
                      in the website. In the
                      event that you need to edit your mobile number, kindly
                      contact Quartz Syiok! Careline at 012-6782283 (Monday to
                      Sunday, 9am–5pm) for further assistance.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I have more than 4 cars?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You are required to contact Quartz Syiok! Careline at
                      012-6782283 to prove that the additional cars are for
                      personal use in order to be eligible for Quartz Syiok!. 
                      Car documents that match owner's name
                      and NRIC must be provided. Quartz Syiok! Careline reserves
                      the right to reject addition of car(s) if information
                      provided is incomplete.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Do I need to download Quartz Syiok!
                      Workshop App?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      No. Quartz Syiok! Workshop App is
                      strictly for the usage of TotalEnergies authourised workshops
                      only.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      I forgot my password. How do I access my account?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Click 'Forgot password' on the login page. You will be
                      given a 6-digit verification code via SMS, to reset your
                      password. <br />
                      For any other enquiries, please contact Quartz Syiok!
                      Careline at 012-6782283 (Monday to Sunday, 9am–5pm) for
                      further assistance.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I want to terminate my Quartz Syiok! ?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Quartz Syiok! will be deactivated
                      automatically after 36 months from the first service if
                      service cycle is not continued. You may also contact Quartz Syiok! Careline at 012-6782283 (Monday to Sunday, 9am–5pm)
                      to remove your information from the loyalty programme.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      What if I want to be removed from all TotalEnergies marketing
                      communication?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Kindly contact Quartz Syiok! Careline at 012-6782283
                      (Monday to Sunday, 9am–5pm) to remove your information
                      from the loyalty programme.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I transfer my free engine oil to another Quartz Syiok!
                      member?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>No. The free bottle is non-transferable.</p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      Can I add a car that is not registered under my name in
                      Quartz Syiok! ?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      No. You may only register your own car(s) under your
                      account.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      When can I redeem my free engine oil from my workshop?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Once the 3-bottle or 4-bottle requirement is met
                      (depending on the engine oil used), all you have to do is
                      complete the same service submission steps and retrieve
                      the Service ID or QR Code, and present it to the workshop
                      for verification. Kindly redeem the free bottle within a
                      service cycle, which is 36 months from the date of the
                      first service.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      I'm an existing member but I would like to sell my car.
                      Can I transfer my current benefits to my account?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Kindly contact Quartz Syiok! Careline at 012-6782283
                      (Monday to Sunday, 9am–5pm) for further assistance.
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      How many bottles I can submit for this loyalty programme
                      within the same day?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      You may submit maximum 2 bottles within the same day for
                      the same car.{" "}
                    </p>
                  </div>
                </div>
                <div className="information-box">
                  <div
                    className="title pr-3"
                    onClick={(e) => openDescription(e)}
                  >
                    <p>
                      If I require 2 bottles (8 liters) to service my car each
                      time, can I claim the free oil on the second visit?
                      <span className="arrow-dropdown"></span>
                    </p>
                  </div>
                  <div className="description">
                    <p>
                      Yes, you may claim the free oil upon meeting the 3-bottle
                      or 4-bottle requirement (depending on the engine oil
                      used).
                      <br />
                      <br />
                      If you are under the 3+1 range: <br />
                      If you have used up 2 bottles during the first visit,
                      during your second visit, you may first submit the first
                      bottle, and then, you will be getting a message prompting
                      that the following bottle will be free. You may redeem the
                      free bottle accordingly by repeating the same process and
                      only the entitled free engine oil variant will be
                      selectable.
                      <br />
                      <br />
                      If you are under the 4+1 range: <br />
                      If you have used up 2 bottles during the first visit,
                      during your second visit, you may submit 2 bottles. You
                      will be entitled to redeem the free bottle during your
                      third visit by repeating the same process and only the
                      entitled free engine oil variant will be selectable.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center mt-4 px-3 px-md-0">
            <div className="col-12 offset-md-2 col-md-8">
              <p className="mb-1 mb-md-4">
                Can’t find the answer you’re looking for?{" "}
              </p>
              <button
                className="tom-button primary-button"
                onClick={() => {
                  window.open("tel:0126782283");
                }}
              >
                CUSTOMER SUPPORT
              </button>
              <br /> <br />
              <button
                className="tom-button default-button"
                onClick={() => {
                  history.push("/loyalty");
                }}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
