import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export default function ServiceStatus(props) {
  const history = useHistory();

  let isClickable = props.isClickable ?? true;

  let vehicle = props.vehicle;
  let services = vehicle.services ?? [];
  let notRejectedServices = services.filter(service => service.status <= 1);
  let bottles = [];

  if (vehicle.cycle_oil_type > 0) {
    for (let i = 0; i < vehicle.cycle_oil_type; i++) {
      let service = notRejectedServices.length > 0 ? notRejectedServices[i] : undefined;
      let visibility = i < vehicle.cycle_oil_type ? "" : "hidden";
      let bottleImg = "/assets/images/bottle-grey.png";

      if (service !== undefined && service.status === 1) {
        bottleImg = service.oil_img;
      }

      bottles.push(
        <div key={i} className="col" style={{ visibility: visibility }}>
          <img src={bottleImg} width="100%" style={{cursor: "pointer"}} />
        </div>
      );
    }

    let freeOilImg = "/assets/images/bottle-free.png";
    let freeService = notRejectedServices.find(service => service.free_oil === true);

    if (freeService != null && vehicle.services.length > vehicle.cycle_oil_type) {
      freeOilImg = freeService.oil_img;
    }

    bottles.push(
      <div className="col" key={5}>
        <img src={freeOilImg} width="100%" />
      </div>
    );

    if (vehicle.cycle_oil_type === 3) {
      bottles.push(
        <div className="col" key={4}></div>
      );
    }

  } else {
    bottles = <div className="pl-md-3">N/A</div>;
  }

  return <Fragment>
    <div className="service-status-section d-flex"
      onClick={() =>
        isClickable ? history.push({
          pathname: "/loyalty/add-service",
          state: {
            guid: vehicle.guid,
            cycle_guid: vehicle.services.filter(x => x.status <= 1)?.length > 0 ? vehicle.cycle_guid : '',
            car_name: `${vehicle.model_brand} ${vehicle.model_name} ${vehicle.plate_number}`,
            plate_number: vehicle.plate_number,
            workshop_id: vehicle.workshop_id,
            cycle_oil_type: vehicle.services.filter(x => x.status <= 1)?.length > 0
              ? vehicle.cycle_oil_type : 0,
            oil_code: vehicle.oil_code,
            free_oil: vehicle.free_oil,
            current_service: vehicle.services.filter(x => x.status <= 1)?.length,
            last_service_date: vehicle.services.filter(x => x.status <= 1)?.length > 0 ? vehicle.services[0].created_date : null,
            current_oils: vehicle.services.filter(x => x.status <= 1).map(x => x.OilCode)
          },
        }) : null
      }
    >{bottles}
    </div>
  </Fragment>;
}
