export const validation_message = {
  login: {
    mobile_no: {
      require: { message: "Mobile No. is required." },
      minLength: { message: "Please provide a valid information" },
      maxLength: { message: "Please provide a valid information" },
      pattern: { message: "Please provide a valid information" },
    },
    password: {
      require: { message: "Please insert your password" },
      minLength: { message: "The information is invalid, please try again" },
      maxLength: { message: "The information is invalid, please try again" },
      pattern: { message: "The information is invalid, please try again" },
    },
  },
  register: {
    full_name: {
      require: { message: "Please provide your full name as per NRIC" },
      minLength: { message: "Please provide your full name as per NRIC" },
      maxLength: { message: "Please provide your full name as per NRIC" },
      pattern: { message: "Please provide your full name as per NRIC" },
    },
    date_birth: {
      require: { message: "Please fill out this field" },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "Please provide a valid Date of Birth" },
    },
    mobile_no: {
      require: { message: "Please provide a valid mobile number" },
      minLength: { message: "Please provide a valid mobile number" },
      maxLength: { message: "Please provide a valid mobile number" },
      pattern: { message: "Please provide a valid mobile number" },
    },
    email: {
      require: { message: "Please provide a valid email address" },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "Please provide a valid email address" },
    },
    password: {
      require: { message: "Please enter your password" },
      minLength: {
        message:
          "Please ensure your password includes min. 8 characters (min. 1 uppercase character, 1 lowercase character, 1 number and 1 special character).",
      },
      maxLength: {
        message:
          "Please ensure your password includes min. 8 characters (min. 1 uppercase character, 1 lowercase character, 1 number and 1 special character).",
      },
      pattern: {
        message:
          "Please ensure your password includes min. 8 characters (min. 1 uppercase character, 1 lowercase character, 1 number and 1 special character).",
      },
    },
    confirm_password: {
      require: { message: "Password confirmation is required" },
      minLength: {
        message: "Password doesn't match. Please enter password again",
      },
      maxLength: {
        message: "Password doesn't match. Please enter password again",
      },
      pattern: {
        message: "Password doesn't match. Please enter password again",
      },
    },
    referal_code: {
      require: { message: "" },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
    tnc: {
      require: {
        message: "Please agree to the Terms & Conditions to proceed.",
      },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
  },
  register_tac: {
    pattern: { message: "Please enter the 6-digit verification code" },
  },
  forgot_password: {
    mobile_no: {
      require: { message: "Mobile No. is required." },
      minLength: { message: "Please provide a valid information" },
      maxLength: { message: "Please provide a valid information" },
      pattern: { message: "Please provide a valid information" },
    },
  },
  forgot_password_tac: {
    tac: { message: "Please enter the 6-digit verification code" },
    password: {
      require: { message: "Please enter your password" },
      minLength: { message: "Please enter your password" },
      maxLength: { message: "Please enter your password" },
      pattern: {
        message:
          "Please ensure your password includes min. 8 characters (min. 1 uppercase character, 1 lowercase character, 1 number and 1 special character).",
      },
    },
    confirm_password: {
      require: { message: "Password confirmation is required" },
      minLength: {
        message: "Password doesn't match. Please enter password again",
      },
      maxLength: {
        message: "Password doesn't match. Please enter password again",
      },
      pattern: {
        message: "Password doesn't match. Please enter password again",
      },
    },
  },
  add_vehicle: {
    vehicle_brand: {
      require: {
        message: "",
      },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
    vehicle_model: {
      require: {
        message: "",
      },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
    plate_no: {
      require: {
        message: "",
      },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
    date_manufacture: {
      require: {
        message: "",
      },
      minLength: { message: "" },
      maxLength: { message: "" },
      pattern: { message: "" },
    },
  },
  add_service: {
    workshop_id: {
      require: {
        message: "Please enter a valid workshop ID",
      },
      minLength: { message: "Please enter a valid workshop ID" },
      maxLength: { message: "Please enter a valid workshop ID" },
      pattern: { message: "Please enter a valid workshop ID" },
    },
    current_mileage: {
      require: {
        message: "Please enter a valid mileage",
      },
      minLength: { message: "Please enter a valid mileage" },
      maxLength: { message: "Please enter a valid mileage" },
      pattern: { message: "Please enter a valid mileage" },
    },
  },
  edit_profile: {},
};
