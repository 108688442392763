import React, { useRef } from "react";
import HeaderSectionComponent from "./header-section/header-section";
import UserOptionSectionComponent from "./user-option-section/user-option-section";
import RegisteredVehicleComponent from "./registered-vehicle/registered-vehicle";
import "./loyalty-dashboard.scss";
import LoyaltyContextProvider from "../../../../provider/loyalty-provider";
import ServiceNotificationComponent from "./service-notification-component/service-notification-component";

export default function LoyaltyScreen(props) {
	return (
		<div className="loyalty-screen">
			<LoyaltyContextProvider>
				<HeaderSectionComponent></HeaderSectionComponent>
				<ServiceNotificationComponent></ServiceNotificationComponent>
				<UserOptionSectionComponent></UserOptionSectionComponent>
        <RegisteredVehicleComponent></RegisteredVehicleComponent>
			</LoyaltyContextProvider>
		</div>
	);
}
