import React, { useEffect } from "react";
import TopBannerComponent from "./top-banner/top-banner";
import HowItWorks from "./how-it-works/how-it-works";
import ReferFriend from "./refer-friend/refer-friend";
import OurProductsComponent from "./our-products/our-products";
import "./home.scss";
import LoyaltyContextProvider from "../../../provider/loyalty-provider";
import ReactPixel from 'react-facebook-pixel';

export default function HomeScreen(props) {
  useEffect(() => {
    const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };

    ReactPixel.init(1055441558192680, advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  return (
    <div className="home-screen">
      <LoyaltyContextProvider>
        <TopBannerComponent></TopBannerComponent>
      </LoyaltyContextProvider>
      <HowItWorks></HowItWorks>
      <OurProductsComponent></OurProductsComponent>
      <ReferFriend></ReferFriend>
    </div>
  );
}
