import React from "react";
import "./header-component.scss";

export default function HeaderComponent(props) {
  return (
    <section className="header-component">
      <h1 className="mb-0" style={{"textTransform": "capitalize"}}>
        {props.plate_number}<br /> {props.model_brand+" "+ props.model_name}
      </h1>
    </section>
  );
}
