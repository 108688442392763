import React from "react";
import ProductChooseComponent from "./product-choose/product-choose";
import BottleQuantityComponent from "./bottle-quantity/bottle-quantity";
import "./step-2.scss";
import PopupContextProvider from "../../../../../provider/popup-provider";

export default function StepTwoComponent(props) {

  return <section className="step-two-component">
    <PopupContextProvider>
      <ProductChooseComponent></ProductChooseComponent>
      <BottleQuantityComponent></BottleQuantityComponent>
    </PopupContextProvider>
  </section>;
}
