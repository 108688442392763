import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import 'react-calendar/dist/Calendar.css';
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { routesComponent } from "./route-component";
import { interceptorInit } from "./helpers/axios-helper";
import { loggerInit } from "./helpers/error-handler";

interceptorInit();
loggerInit();

ReactDOM.render(
  routesComponent(),
  document.getElementById("root")
)

serviceWorker.register();
