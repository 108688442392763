import React from "react";
import "./progressbar.scss";

export default function ProgressBar(props) {
    return (
        <div className="progress-bars">
            <Filler percentage={props.percentage} />
            <span>{props.percentage}</span>
        </div>
    )
}

const Filler = (props) => {
    return <div className="filler" style={{ width: `${props.percentage}%` }}></div>
}