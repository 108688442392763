import React, { useEffect } from "react";
import WelcomeComponent from "../../welcome/welcome";
import SendOtpFormComponent from "./send-otp-form/send-otp-form";
import "./send-otp-screen.scss";
import PopupContextProvider from "../../../../provider/popup-provider";
import { useHistory } from "react-router-dom";

export default function SendOtpForgotPasswordScreen(props) {
  const history = useHistory();

  const getRegisterMobileNumber = () => {
    if (props.history.location.state === undefined) {
      history.replace("/forgot-password");
    } else {
      const register_phone = props.history.location.state.register_phone;
      document.getElementById("mobile-no").innerText =
        "+" + register_phone + ".";
    }
  };

  useEffect(() => {
    getRegisterMobileNumber();
  }, []);

  

  return (
    <div className="send-otp-forgot-password">
      <PopupContextProvider>
        <WelcomeComponent></WelcomeComponent>
        <SendOtpFormComponent
          state_history={props.history.location.state}
        ></SendOtpFormComponent>
      </PopupContextProvider>
    </div>
  );
}
