import React from "react";
import "./non-login-template.scss";
import BottomNavigationComponent from "../global-component/bottom-navigation/bottom-navigation";
import NavbarComponent from "../global-component/navbar/navbar";
import NavActiveContextProvider from "../../provider/navbar-provider";
import InboxContextProvider from "../../provider/inbox-provider";
import SideNavigationComponent from "../global-component/side-navigation/side-navigation";
import UserContextProvider from "../../provider/user-provider";
import PopupContextProvider from "../../provider/popup-provider";
import PWAPrompt from "react-ios-pwa-prompt";
import FooterComponent from "../global-component/footer/footer";

const NonLoginTemplate = (props) => {
  return (
    <div className="non-login-template">
      <PopupContextProvider>
        <NavActiveContextProvider current_page={props.nav_active}>
          <UserContextProvider>
            <InboxContextProvider>
              <PWAPrompt />
              <NavbarComponent></NavbarComponent>
              {props.children}
            </InboxContextProvider>
            <BottomNavigationComponent></BottomNavigationComponent>
            <SideNavigationComponent></SideNavigationComponent>
            <FooterComponent></FooterComponent>
          </UserContextProvider>
        </NavActiveContextProvider>
      </PopupContextProvider>
    </div>
  );
}

export default NonLoginTemplate;
