import React, { useEffect, useState } from "react";
import HeaderComponent from "./header/header-component";
import ServiceInfoComponent from "./service-info/service-info";
import ProgrammeDateComponent from "./programme-date/programme-date";
import ServiceProductDetailComponent from "./service-product-detail/service-product-detail";
import PreviousCycleComponent from "./previous-cycle/previous-cycle";
import "./service-information-screen.scss";
import { apiURL } from "../../../../config/config";
import Axios from "axios";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";

export default function ServiceInformationScreen(props) {
  let vehicle_info = props.location.state?.vehicle;

  const [previousCycles, setPreviousCycles] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (vehicle_info === undefined) {
      history.replace("/loyalty");
    }
    const getVehicleCycles = async () => {
      try {
        const response = await Axios.get(
          `${apiURL}/api/vehicle/${vehicle_info.guid}/cycles`,
          {
            headers: {
              Authorization: "Bearer " + Cookie.get("access_token"),
            },
          }
        );

        let result = response.data.result;

        if (Array.isArray(result)) {
          let previousCycles = result.filter((cycle) => cycle.status == 1);
          if (previousCycles.length > 0) {
            setPreviousCycles(previousCycles);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    
    getVehicleCycles();
  }, []);

  return (
    <div className="service-information-screen">
      {vehicle_info !== undefined ? (
        <>
          <HeaderComponent
            plate_number={vehicle_info?.plate_number}
            model_brand={vehicle_info?.model_brand}
            model_name={vehicle_info?.model_name}
          ></HeaderComponent>
          <ServiceInfoComponent vehicle={vehicle_info}></ServiceInfoComponent>
          <ProgrammeDateComponent
            start_date={vehicle_info?.services.filter(x => x.status <= 1).length > 0 ? vehicle_info?.cycle_start_date: ''}
            expiry_date={vehicle_info?.services.filter(x => x.status <= 1).length > 0 ? vehicle_info?.cycle_expiry_date: ''}
          ></ProgrammeDateComponent>
          <ServiceProductDetailComponent
            cycle={vehicle_info.cycle_guid}
            vehicle_info={vehicle_info}
          ></ServiceProductDetailComponent>
          <PreviousCycleComponent
            previousCycles={previousCycles}
            vehicle_info={vehicle_info}
          ></PreviousCycleComponent>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
