import axios from 'axios';
import Cookies from 'js-cookie';
import { apiURL } from '../../../../config/config';

export default async function DashboardAPI() {
    try {
        const response = await axios.get(
          `${apiURL}/api/loyalty`, 
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('access_token')
            }
          }
        );
        return response.data;
      } catch (error) {
        return {
          status_code: 502
        }
      }
}