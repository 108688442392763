import React from "react";
import "./sidebar.scss";
import SidebarItemComponent from "./sidebar-item/sidebar-item";

export default function SidebarComponent(props) {
  const closeSidebar = () => {
    props.toggleMenu();
  };

  return (
    <div className={"sidebar-component " + props.sidebar_status}>
      <div className="close-section" onClick={() => props.toggleMenu()}></div>
      <div className="item"><SidebarItemComponent closeSidebar={closeSidebar}></SidebarItemComponent></div>
    </div>
  );
}
