import React from "react";
import { Link } from "react-router-dom";
import TopSectionScreen from "../../../screens/general-screen/top-section/top-section";
import "./404-screen.scss";

export default function PageNotFoundScreen() {
  return (
    <div className="page-not-found-screen">
      <TopSectionScreen title="ERROR 404"></TopSectionScreen>
      <section className="content-section">
        <div className="container">
          <div className="row px-3 px-md-0 text-center">
            <div className="offset-3 col-6 offset-md-4 col-md-4 px-md-5 mb-4">
              <img src="/assets/images/not-found-icon.png" width="100%" />
            </div>
            <div className="col-12 offset-md-2 col-md-8">
              <h3 className="text-primary text-responsive text-xxxlg">Oops!</h3>
              <p className="mb-4">
                The page you were looking is not available. <br /> Either it has
                been moved or removed, or there was a <br /> problem with the
                link you followed.
              </p>
              <Link className="text-primary" to={"/"}>
                Go back to the home page?
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
