import React, { useState, useRef, useContext } from "react";
import "./add-vehicle-form.scss";
import { useHistory } from "react-router-dom";
import { AddVehicleAPI } from "./add-vehicle-api";
import { PopupContext } from "../../../../../provider/popup-provider";
import { ErrorMessage } from "@hookform/error-message";
import {
  formatDateYear,
} from "../../../../../helpers/moment-helper";
import {
  minLengthMessage,
  requiredMessage,
} from "../../../../../helpers/form-validation-message";
import { useForm } from "react-hook-form";
import SpinnerComponent from "../../../../../templates/global-component/spinner-component/spinner-component";
import { errorMessageHandler } from "../../../../../helpers/error-handler";
import TagManager from "react-gtm-module";

export default function AddVehicleFormScreen(props) {
  const history = useHistory();
  const { popup, setPopup, closePopup } = useContext(PopupContext);

  const { register, handleSubmit, errors, watch } = useForm();
  const [vehicle_brand, setVehicleBrand] = useState("");
  const [vehicle_model, setVehicleModel] = useState("");
  const [car_model_value, selectCarModelValue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  var car_list = require("./car-model-data.json");
  const year_manufacture = [];

  for (let x = +formatDateYear(new Date()); x > 1950; x--) {
    year_manufacture.push(x);
  }

  const selectVehicleBrand = (e) => {
    if (e.target.value === "others") {
      setVehicleBrand("others");
    } else {
      setVehicleBrand("");
      selectCarModelValue(
        car_list.filter((x) => x.brand === e.target.value)[0].model
      );
    }
  };

  const selectVehicleModel = (e) => {
    if (e.target.value === "others") {
      setVehicleModel("others");
    } else {
      setVehicleModel("");
    }
  };

  const onSubmit = async (data) => {
    TagManager.dataLayer({ dataLayer: { event: 'Add Vehicle', cta_button: 'Add Vehicle' } });
    setIsLoading(true);
    if (!isLoading) {
      const response = await AddVehicleAPI(
        data.vehicle_brand === "others"
          ? data.vehicle_brand_others
          : data.vehicle_brand,
        data.vehicle_model,
        data.car_plate_number,
        0,
        data.date_of_manufacture
      );

      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      if (response.status_code == 200) {
        popupVal.content = [
          `Thank you! Your vehicle ${data.car_plate_number} has been added to your car list.`,
        ];
        popupVal.img = "success-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "GO TO DASHBOARD",
            tap: () => {
              TagManager.dataLayer({ dataLayer: { event: 'Add Vehicle', cta_button: 'add vehicle successful' } });
              history.replace({ pathname: "/loyalty" });
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      } else if (response.status_code == 502) {
          popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
          popupVal.img = "danger-icon";
          popupVal.buttons = [
            {
              type: "primary",
              text: "CLOSE",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        }
    }
  };

  return (
    <section className="add-vehicle-form">
      <div className="container pt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
              <div className="input-control">
                <select
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please select a vehicle brand",
                    },
                  })}
                  name="vehicle_brand"
                  className="tom-input"
                  onChange={(e) => selectVehicleBrand(e)}
                  placeholder="Vehicle brand"
                >
                  <option value="">Vehicle brand</option>
                  {car_list.map((data, index) => {
                    return (
                      <option key={index} value={data.brand}>
                        {data.brand}
                      </option>
                    );
                  })}
                  <option value="others">Others</option>
                </select>
                <small className="error">
                  <ErrorMessage errors={errors} name="vehicle_brand" />
                </small>
              </div>
            </div>
            {/* <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
                <div className="input-control">
                  <select
                    ref={register({
                      required: {
                        value: true,
                        message: "*Please select vehicle model",
                      },
                    })}
                    name="vehicle_model"
                    className="tom-input"
                  >
                    <option value="">Vehicle model</option>
                    {car_model_value.map((data, index) => {
                      return (
                        <option key={index} value={data.name}>
                          {data.name}
                        </option>
                      );
                    })}
                    <option value="others">Others</option>
                  </select>
                  <small className="error">
                    <ErrorMessage errors={errors} name="vehicle_model" />
                  </small>
                </div>
            </div> */}
            {vehicle_brand === "others" ? (
              <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
                <div className="input-control">
                  <input
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "*Please fill out this field",
                      },
                      minLength: {
                        value: 3,
                        message: minLengthMessage("Vehicle brand", 3),
                      },
                    })}
                    name="vehicle_brand_others"
                    className="tom-input"
                    placeholder="Vehicle brand"
                    maxLength="20"
                  />
                  <small className="error">
                    <ErrorMessage errors={errors} name="vehicle_brand_others" />
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
              <div className="input-control">
                {vehicle_brand === "others" ? (
                  <input
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "*Please fill out this field",
                      },
                      minLength: {
                        value: 3,
                        message: minLengthMessage("Vehicle model", 3),
                      },
                    })}
                    name="vehicle_model"
                    className="tom-input"
                    placeholder="Vehicle model"
                    maxLength="20"
                  />
                ) : (
                  <select
                    ref={register({
                      required: {
                        value: true,
                        message: "*Please select vehicle model",
                      },
                    })}
                    name="vehicle_model"
                    className="tom-input"
                    onChange={(e) => selectVehicleModel(e)}
                  >
                    <option value="">Vehicle model</option>
                    {car_model_value.map((data, index) => {
                      return (
                        <option key={index} value={data.name}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                )}
                <small className="error">
                  <ErrorMessage errors={errors} name="vehicle_model" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
              <div className="input-control">
                {vehicle_model === "others" ? (
                  <input
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "*Please fill out this field",
                      },
                      minLength: {
                        value: 3,
                        message: minLengthMessage("Vehicle model", 3),
                      },
                    })}
                    name="vehicle_model"
                    className="tom-input"
                    placeholder="Vehicle model"
                    maxLength="20"
                  />
                ) : ( "" )}
                <small className="error">
                  <ErrorMessage errors={errors} name="vehicle_model_others" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-md-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Car plate number"),
                    },
                    minLength: {
                      value: 2,
                      message: "*Please enter a valid car plate number",
                    },
                    maxLength: {
                      value: 20,
                      message: "*Please enter a valid car plate number",
                    },
                    pattern: {
                      value: /^[0-9a-zA-Z]+$/,
                      message: "*Please enter a valid car plate number",
                    },
                  })}
                  name="car_plate_number"
                  className="tom-input"
                  placeholder="Car plate number"
                  maxLength="20"
                  onKeyUp={(event) => {
                    event.target.value = event.target.value.toUpperCase();
                    event.target.value = event.target.value.split(' ').join('');
                  }}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="car_plate_number" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 mb-4 mb-md-5">
              <div className="input-control">
                <select
                  ref={register({})}
                  name="date_of_manufacture"
                  className="tom-input"
                >
                  <option value="">Year of manufacture</option>
                  {year_manufacture.map((data, index) => {
                    return (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    );
                  })}
                </select>
                <small className="error">
                  <ErrorMessage errors={errors} name="date_of_manufacture" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0">
              <button
                type="submit"
                className="tom-button primary-button"
                disabled={isLoading}
              >
                Add Vehicle
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}