import React from "react";
import "./content-component.scss";

export default function ContentComponent(props) {
  return (
    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <img
              src="/assets/images/TotalEnergies-Office.jpeg"
              alt="About TotalEnergies Marketing Malaysia"
              width="100%"
              className="mb-3"
            />
          </div>
          <div className="col-12 col-md-6">
            <p>
            First introduced in Malaysia under the moniker ELF in 1985, later as Total in 1991, the company has expanded its footprint from engine oil to glass coatings, industrial oil, and specialty products. It serves various industries and sectors including agriculture, automotive, aviation, and more. 
            Today, the company has over 1000 workshops and 35 distributors nationwide. It also works closely with a few major OEM (Original Equipment Manufacturer) accounts such as Proton, Cycle and Carriage, and TC Euro (Renault). Segments include workshops, motorcycle outlets, spare parts distributors and direct users (industrial division).
            </p>
            <p>
            In 2021, in line with the company’s strategic transformation into a broad energy company, committed to producing and providing energies that are ever more affordable, reliable and clean, the company has rebranded itself as TotalEnergies. TotalEnergies is now a global multi-energy company that produces and markets energies on a global scale: oil and biofuels, natural gas and green gases, renewables and electricity. To contribute to the sustainable development of the planet facing the climate challenge, TotalEnergies is moving forward, together, towards new energies. Our ambition is to be a world-class player in the energy transition.
            </p>
            <p>Follow us on TotalEnergies Facebook page <a href="https://www.facebook.com/TotalEnergiesMalaysia/?brand_redir=148758255215603" target="_blank" className="text-primary">here</a> to stay updated with the latest news or learn more about <a href="https://www.totalenergies.my/" target="_blank" className="text-primary">TotalEnergies Marketing Malaysia Sdn. Bhd.</a> here.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
