import React from "react";
import ContentSection from "./content-section/content-section";
import WelcomeComponent from "../../login/welcome/welcome";
import "./mgm-success.scss";

export default function MemberGetMemberSuccessScreen(props) {
    return (
        <div className="member-get-member-success-screen">
            <WelcomeComponent></WelcomeComponent>
            <ContentSection></ContentSection>
        </div>
    );
}
