import Axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { apiURL } from "../../../../../config/config";
import { formatDate } from "../../../../../helpers/moment-helper";
import ServiceProductListComponent from "../service-product-detail/service-product-list";
import "./previous-cycle.scss";

export default function PreviousCycleComponent(props) {
  const previousCycles = props.previousCycles;
  const [serviceDetail, setserviceDetail] = useState([]);

  const expandServiceHistory = (cycle_guid) => {
    const getServiceHistoryDetail = async () => {
      try {
        const response = await Axios.get(
          `${apiURL}/api/cycle/${cycle_guid}/services`
        );
        let result = response.data.result;
        setserviceDetail(result);
      } catch (error) {
        console.log(error);
      }
    };
    getServiceHistoryDetail();
  };

  return (
    <section className="previous-cycle-component">
      <div className="container">
        <div className="row px-2">
          <div className="col-12">
            <h2 className="title mb-4">
              Service history
            </h2>
          </div>
        </div>
        <div className="row pt-4 pb-5">
          {previousCycles.length > 0 ? (
            previousCycles.map((cycle, index) => {
              return (
                <Fragment>
                  <div
                    className="offset-1 offset-md-0 col-10 col-md-12 mb-3"
                    onClick={() => expandServiceHistory(cycle.guid)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="detail">
                      <button type="button" className="mb-0 tom-button primary-button">{formatDate(cycle.created_date)}</button>
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    {serviceDetail != null && serviceDetail.length > 0 &&
                      cycle.guid === serviceDetail[0].cycle_guid ? (
                        <div className="row">
                          <ServiceProductListComponent
                            services={serviceDetail}
                            vehicle_info={props.vehicle_info}
                          ></ServiceProductListComponent>
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                </Fragment>
              );
            })
          ) : (
              <div className="col-12 text-center">No history found.</div>
            )}
        </div>
      </div>
    </section>
  );
}