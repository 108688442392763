import axios from 'axios';
import Cookies from 'js-cookie';
import querystring from "querystring";
import { apiURL } from '../../../../config/config';

export async function CheckInitialValidation(
  workshop_id,
  mileage,
  cycle_guid
) {
  try {
    const response = await axios.post(
      `${apiURL}/api/service/intial-validate`,
      querystring.stringify({
        workshop_id: workshop_id,
        mileage: mileage,
        cycle_guid: cycle_guid
      }),
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
      }
    );
    return response.data;
  } catch (error) {
    return {
      status_code: 502
    }
  }
  
}

export async function AddServiceAPI(
  guid,
  current_mileage,
  workshop_id,
  oil,
  bottle_no,
  receipt_img
) {
  let formData = new FormData();
  formData.append("vehicle_guid", guid);
  formData.append("current_mileage", current_mileage);
  formData.append("workshop_id", workshop_id);
  formData.append("oil", oil);
  formData.append("total_bottle", bottle_no);
  formData.append("receipt_img", receipt_img);

  try {
    const response = await axios.post(
      `${apiURL}/api/service`, 
      formData, 
      {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('access_token')
      }
    }
    );
    return response.data;
  } catch (error) {
    return {
      status_code: 502
    }
  }
}

export async function CheckServiceAPI(transactionCode) {
  try {
    const response = await axios.get(`${apiURL}/api/service/check/${transactionCode}`,
    {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('access_token')
      }
    });

    return response.data;
  } catch (error) {
    return {
      status_code: 502
    }
  }
}

export async function CheckCeasedAPI(workshop_id) {
  try {
    const response = await axios.get(`${apiURL}/api/service/check_workshop/${workshop_id}`,
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
      });

    return response.data;
  } catch (error) {
    return {
      status_code: 502
    }
  }
}