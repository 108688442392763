import React, { useContext, useEffect, useState } from "react";
import "./survey-form-component.scss";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "axios";
import Cookies from "js-cookie";
import querystring from "querystring";
import { apiURL } from "../../../../config/config";
import { PopupContext } from "../../../../provider/popup-provider";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import { errorMessageHandler } from "../../../../helpers/error-handler";
import TagManager from "react-gtm-module";

export default function SurveyFormComponent(props) {
  const { register, handleSubmit } = useForm();
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("survey") === null) {
      history.push("/loyalty");
    }
    sessionStorage.clear("survey");
  }, []);

  const onSubmit = async (data) => {
    TagManager.dataLayer({ dataLayer: { event: 'Reg survey', cta_button: 'reg survey submit' } });

    setIsLoading(true);
    if (!isLoading) {
      let content = [];
      let imageIcon = "";
      let buttons = [];

      if (data.answer == null) {
        content = [`Please answer the question!`];
        imageIcon = "danger-icon";
        buttons = [
          {
            type: "primary",
            text: "OK",
            tap: () => {
              closePopup();
              setIsLoading(false);
            },
          },
        ];
      } else if (data.answer === "OTHERS" && data.other === "") {
        content = [`Please enter a comment if you select “Other”.`];
        imageIcon = "danger-icon";
        buttons = [
          {
            type: "primary",
            text: "OK",
            tap: () => {
              closePopup();
              setIsLoading(false);
            },
          },
        ];
      } else {
        try {
          const response = await Axios.post(
            `${apiURL}/api/survey`,
            querystring.stringify({
              answer: data.answer !== "OTHERS" ? data.answer.toString() : data.other,
              answer2: data.answer2 != null ? data.answer2 : "no"
            }),
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
              },
            }
          );
          
          if (response.data.status_code == 200) {
            content = ["Thank you for completing our survey!"];
            imageIcon = "success-icon";
            buttons = [
              {
                type: "primary",
                text: "GO TO DASHBOARD",
                tap: () => {
                  TagManager.dataLayer({ dataLayer: { event: 'Reg survey', cta_button: 'reg complete go to dashboard' } });
                  history.replace("/loyalty");
                },
              },
            ];
          } else {
            let msg = errorMessageHandler(response.data);
            content = [msg];
            imageIcon = "danger-icon";
            buttons = [
              {
                type: "primary",
                text: "TRY AGAIN",
                tap: () => {
                  closePopup();
                  setIsLoading(false);
                },
              },
            ];
          }
        } catch (error) {
          content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
          imageIcon = "danger-icon";
          buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
                setIsLoading(false);
              },
            },
          ];
        }
      }
      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.img = imageIcon;
      popupVal.content = content;
      popupVal.buttons = buttons;
      setPopup(popupVal);
    }
  };

  return (
    <section className="survey-form-component">
      <div className="container">
        <div className="content">
          <div className="row mb-4">
            <div className="col">
              <h2 className="title">
                Survey / 调查
              </h2>
            </div>
          </div>
          <form
            className="row mb-4 offset-0 col-12 offset-md-2 col-md-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-4">
              <p className="description">
                Have you used TotalEnergies oil before? / 您曾使用过TotalEnergies产品吗？
              </p>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer2"
                    value="yes"
                  />
                  <span className="checkmark"></span>Yes / 是<small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer2"
                    value="no"
                  />
                  <span className="checkmark"></span>No / 不是
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <p className="description">
                How did you hear about <b>Quartz Syiok!</b>? / 您是如何知道
                <b>Quartz Syiok!</b>?
              </p>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Through a friend"
                  />
                  <span className="checkmark"></span>Through a friend / 透过朋友
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Referred by a workshop"
                  />
                  <span className="checkmark"></span>Referred by a workshop /
                  透过维修车厂介绍
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Social media (Facebook,
                      Instagram)"
                  />
                  <span className="checkmark"></span>Social media (Facebook,
                  Instagram) / 社交媒体
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Online search (Google,
                      Yahoo, etc.)"
                  />
                  <span className="checkmark"></span>Online search (Google,
                  Yahoo, etc.) / 上网搜寻
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Advertisements on websites"
                  />
                  <span className="checkmark"></span>Advertisements on websites
                  / 广告/ 网站
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="Offline advertisements
                    (buntings, posters, petrol station)"
                  />
                  <span className="checkmark"></span>Offline advertisements{" "}
                  <br />
                  (buntings, posters, petrol station) / <br /> 线下广告
                  (海报，加油站)
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="radio"
                    ref={register}
                    name="answer"
                    value="OTHERS"
                  />
                  <span className="checkmark"></span>
                  <span>
                    Other <i>(Please specify below)</i> / 其他 (请填写)
                  </span>
                  <small></small>
                </label>
              </div>
            </div>
            <div className="px-4 col-12">
              <div className="input-control">
                <input
                  ref={register}
                  type="text"
                  placeholder="Other / 其他"
                  name="other"
                  id="other"
                  className="tom-input default"
                  autoComplete="off"
                />
                <small></small>
              </div>
            </div>

            <div className="container">
              <div className="row mt-2">
                <div className="col-12 mb-3">
                  <button className="tom-button primary-button">
                    SUBMIT / 提交
                    {isLoading ? (
                      <SpinnerComponent
                        fontColor={"text-white"}
                        dimensions={{ mobile: "14px", desktop: "1rem" }}
                      ></SpinnerComponent>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
                <div className="col-12 text-center">
                  <p className="skip-survey text-primary"
                    id="skip-survey"
                    style={{"cursor": "pointer"}}
                    onClick={() => {
                      TagManager.dataLayer({ dataLayer: { event: 'Reg survey', cta_button: 'reg Skip Survey' } });          
                      history.replace("/loyalty");
                    }}>
                    Skip survey / 省略此调查
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}