import React, { createContext, useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
export const PopupContext = createContext();

const PopupContextProvider = (props) => {
  const [popup, setPopup] = useState({
    open: false,
    img: "",
    content: [],
    buttons: [],
  });

  const closePopup = () => {
    let popupVal = Object.assign({}, popup);
    popupVal.open = false;
    setPopup(popupVal);
  };

  useEffect(() => {
    popup.open
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");      
  }, [popup.open]);

  return (
    <PopupContext.Provider value={{ popup, setPopup, closePopup }}>
      <div
        className={`popup-component ${popup.open ? "active" : ""}`}
        id="verify-popup"
      >
        <div className="popup-verification-status" id="wrong-verification">
          {popup.img != "danger-icon" && popup.img != "" ? (
            <div className="row">
              <div className="offset-4 col-4">
                <img className="mb-3" src={_getImage(popup.img)} width="100%" />
              </div>
            </div>
          ) : (
            <i className="icons-warning" style={{fontSize: "4rem"}}></i>
          )}
          <p dangerouslySetInnerHTML={{ __html: popup.content.join("<br/>") }} />
          <div className="row">
            {popup.buttons != null
              ? popup.buttons.map((x, index) => {
                  return (
                    <div className={"col"} key={index}>
                      <button
                        className={`tom-button ${x.type}-button`}
                        onClick={() => x.tap()}
                      >
                        {x.text}
                      </button>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      {props.children}
    </PopupContext.Provider>
  );
};

function _getImage(icon) {
  switch (icon) {
    case "success-icon":
      return "/assets/images/pop-up/success-icon.jpg";

    case "danger-icon":
      return "/assets/images/pop-up/danger-icon.jpg";

    case "pending-icon":
      return "/assets/images/pop-up/pending-icon.jpg";
  }
}

export default PopupContextProvider;