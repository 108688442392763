import React, { useContext, useEffect, useRef, useState } from "react";
import { startTimer } from "../../../../../helpers/countdown";
import "./send-otp-form.scss";
import axios from "axios";
import Cookie from "js-cookie";
import querystring from "querystring";
import { Link } from "react-router-dom";
import { apiURL } from "../../../../../config/config";
import { PopupContext } from "../../../../../provider/popup-provider";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validation_message } from "../../../../../data/validation_message";
import { togglePassword } from "../../../../../helpers/toggle-password";
import SpinnerComponent from "../../../../../templates/global-component/spinner-component/spinner-component";
import { UserContext } from "../../../../../provider/user-provider";
import PinField from "react-pin-field";

export default function SendOtpFormComponent(props) {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { getUserProfile } = useContext(UserContext);
  const { register, handleSubmit, watch, errors } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const [isLoading, setIsLoading] = useState(false);

  const otpRef = useRef("");
  const [otpValue, setOtpValue] = useState("");

  useEffect(() => {
    countDownOtp();
  }, []);

  const countDownOtp = () => {
    const threeMinutes = 60 * 3,
      display = document.querySelector("#time"),
      expired_countdown = document.getElementById("expired-coundown"),
      expired_content = document.getElementById("expired-content");
    expired_countdown.style.display = "block";
    expired_content.style.display = "none";
    startTimer(threeMinutes, display, expired_countdown, expired_content);
  };

  const onChangeOtpNumber = () => {
    let otp_value = "";
    otpRef.current.forEach((input) => (otp_value = otp_value + input.value));
    setOtpValue(otp_value);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isLoading) {
      try {
        let url = `${apiURL}/api/user/password/reset`;
        let requestBody = {
          token: props.state_history.reset_token,
          password: data.password,
          tac: data.otp_field,
        };

        let config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await axios.post(
          url,
          querystring.stringify(requestBody),
          config
        );

        // Popup variables
        let content = [];
        let imageIcon = "success-icon";
        let buttons = [];
        // check for status code
        if (response.data.status_code == 200) {
          // api if success
          Cookie.set("access_token", response.data.result.access_token, {
            expires: 180,
          });
          Cookie.set("refresh_token", response.data.result.refresh_token, {
            expires: 180,
          });

          // Update user context
          await getUserProfile();

          imageIcon = "success-icon";

          content = ["You've successfully reset your password."];
          buttons = [
            {
              type: "primary",
              text: "BACK TO DASHBOARD",
              tap: () => {
                window.location.replace("/loyalty");
              },
            },
          ];
        } else {
          // TODO: api if got error
          imageIcon = "danger-icon";
          content = ["Please provide a valid information"];
          buttons = [
            {
              type: "primary",
              text: "TRY AGAIN",
              tap: () => {
                closePopup();
              },
            },
          ];
          setIsLoading(false);
        }

        sessionStorage.removeItem("reset_token");

        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = imageIcon;
        popupVal.content = content;
        popupVal.buttons = buttons;
        setPopup(popupVal);
      } catch (error) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "CLOSE",
            tap: () => {
              closePopup();
            },
          },
        ];
        setPopup(popupVal);
        setIsLoading(false);
      }
    }
  };

  return (
    <section className="send-otp-forgot-password-component">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="mb-0 title">
              Password Reset
            </h2>
          </div>
        </div>
        <div className="row mt-4 mt-md-0 mb-3">
          <div className="offset-1 offset-md-2 col-10 col-md-8">
            <p className="text-center information">
              A 6 digit verification code has been sent to <br className="d-none d-md-block" /> your mobile
              number: &nbsp;
              <b id="mobile-no" className="text-primary"></b>
              <br className="d-none d-md-block" /> Please enter the code below.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-md-2">
            <div className=" input-control">
              <input
                type="number"
                ref={register({
                  required: {
                    value: true,
                    message:
                      "*" + validation_message.register_tac.pattern.message,
                  },
                  minLength: {
                    value: 6,
                    message:
                      "*" + validation_message.register_tac.pattern.message,
                  },
                })}
                id="otp_field"
                name="otp_field"
                inputMode="decimal"
                className="tom-input"
                onChange={(e) => {
                  if (e.target.value.toString().length > 6) {
                    return (e.target.value = e.target.value.substr(0, 6));
                  }
                }}
              />
            </div>

            <small className="error">
              <ErrorMessage errors={errors} name="otp_field" />
            </small>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8">
            <div className="input-control">
              <input
                type="password"
                ref={register({
                  required: {
                    value: true,
                    message: `
                        *${validation_message.forgot_password_tac.password.require.message}`,
                  },
                  pattern: {
                    value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>_~`+-]).{8,}$/,
                    message: `
                        *${validation_message.forgot_password_tac.password.pattern.message}`,
                  },
                })}
                id="password"
                className="tom-input"
                name="password"
                placeholder="Password"
                autoComplete="off"
              />
              <span
                className="icon-eye"
                onClick={(e) => {
                  togglePassword(e, "password");
                }}
              ></span>
              <p>
                <i>
                  Please ensure your password includes min. 8 characters (min. 1
                  uppercase character, 1 lowercase character, 1 number and 1
                  special character).
                </i>
              </p>
              <small className="error">
                <ErrorMessage errors={errors} name="password" />
              </small>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
            <div className="input-control">
              <input
                type="password"
                ref={register({
                  required: {
                    value: true,
                    message: `
                        *${validation_message.forgot_password_tac.confirm_password.require.message}`,
                  },
                  validate: (value) =>
                    value === password.current ||
                    `
                        *${validation_message.forgot_password_tac.confirm_password.pattern.message}`,
                })}
                id="password_confirmation"
                className="tom-input"
                name="password_confirm"
                placeholder="Confirm Password"
                autoComplete="off"
              />
              <span
                className="icon-eye"
                onClick={(e) => {
                  togglePassword(e, "password_confirmation");
                }}
              ></span>
              <small className="error">
                <ErrorMessage errors={errors} name="password_confirm" />
              </small>
            </div>
          </div>
          <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
            <button className="tom-button primary-button" disabled={isLoading}>
              Reset Password
              {isLoading ? (
                <SpinnerComponent
                  fontColor={"text-white"}
                  dimensions={{ mobile: "14px", desktop: "1rem" }}
                ></SpinnerComponent>
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
        <div className="row">
          <div className="offset-1 offset-md-2 col-10 col-md-8">
            <p className="information text-center">
              Don't receive the verification code? <br />
              Please check that you entered the <br />
              correct mobile number.
            </p>
            <p className="information text-center">
              <i>
                <b>or</b>
              </i>
            </p>
            <p id="expired-coundown" className="information text-center">
              <span className="text-primary">Resend</span> code in{" "}
              <span id="time" className="text-primary">
                00
              </span>{" "}
              seconds
            </p>
            <p
              id="expired-content"
              className="text-center"
              onClick={() => countDownOtp()}
            >
              Resend Code
            </p>
            <p className="information text-center">
              If you still don't receive the verification code, <br />
              <Link className="text-primary" to={"/contact-support"}>
                contact support.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}