export const requiredMessage = (name) => {
    return `*${name} is required`;
};

export const minLengthMessage = (name, min) => {
    return `*${name} must be at least ${min} characters`;
};

export const maxLengthMessage = (name, max) => {
    return `*${name} must be less than ${max} characters`;
};

export default {
    requiredMessage,
    minLengthMessage,
    maxLengthMessage
};
