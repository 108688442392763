import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { minLengthMessage, requiredMessage } from '../helpers/form-validation-message';
import { PopupContext } from './popup-provider';
import { AddServiceContext } from './add-service-provider';
import StepTwoComponent from '../screens/dashboard-screen/loyalty-dashboard/add-service-screen/step-2/step-2';
import { errorMessageHandler } from '../helpers/error-handler';
import SpinnerComponent from '../templates/global-component/spinner-component/spinner-component';
import { CheckCeasedAPI } from '../screens/dashboard-screen/loyalty-dashboard/add-service-screen/add-service-api';

export const CeasedPopupContext = createContext();

export default function CeasedPopupContextProvider(props) {
    const { register, handleSubmit, errors } = useForm();
    const { service, setService } = useContext(AddServiceContext);
    const { popup, setPopup, closePopup } = useContext(PopupContext);
    const [isLoading, setIsLoading] = useState(false);

    const [error_Message, setErrorMessage] = useState("");

    const [ceasedPopup, setCeasedPopup] = useState({
        current_mileage: "",
        current_workshop_id: "",
        open: false,
        buttons: []
    });

    useEffect(() => {
        ceasedPopup.open
            ? (document.body.style.overflowY = "hidden")
            : (document.body.style.overflowY = "auto");
    }, [ceasedPopup.open]);

    const onSubmit = async (data) => {
        setIsLoading(true);
        const popupVal = Object.assign({}, popup);

        const response = await CheckCeasedAPI(
            data.workshop_id
        );

        if (response.status_code == 200) {
            let serviceInfo = Object.assign({}, service);
            serviceInfo.current_mileage = ceasedPopup.current_mileage;
            serviceInfo.workshop_id.floating = ceasedPopup.current_workshop_id;
            serviceInfo.workshop_id.new_workshop_id = data.workshop_id;
            serviceInfo.current_service.floating = service.current_service.ori;
            serviceInfo.oil.float = service.oil.ori;
            serviceInfo.screen = <StepTwoComponent></StepTwoComponent>;
            setService(serviceInfo);
        } else if (response.status_code == 404) {
            setErrorMessage("Please enter a valid workshop ID!");

            setIsLoading(false);
        } else if (response.status_code == 502) {
            popupVal.open = true;
            popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
            popupVal.img = "danger-icon";
            popupVal.buttons = [
              {
                type: "primary",
                text: "Close",
                tap: () => {
                  closePopup();
                },
              },
            ];
          }
        setPopup(popupVal);
    }

    return <CeasedPopupContext.Provider value={{ ceasedPopup, setCeasedPopup }}>
        <div
            className={`popup-component ${ceasedPopup.open ? "active" : ""}`}
            id="verify-popup" style={{ zIndex: "10000000" }}
        >
            <div className="popup-verification-status" id="wrong-verification">
                <p>The Workshop ID that you've inserted is no longer part of Quartz Syiok! Program. <br /> Please insert a new Workshop ID.</p>
                <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                        <div className="col">
                            <div className="input-control">
                                <input
                                    type="text"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: requiredMessage("Workshop ID"),
                                        },
                                        minLength: {
                                            value: 4,
                                            message: minLengthMessage("Workshop ID", 4),
                                        },
                                    })}
                                    name="workshop_id"
                                    placeholder="Workshop ID"
                                    className="tom-input"
                                    autoComplete="off"
                                    maxLength="10"
                                />
                                {error_Message ? <small className="error mb-1"><b>{error_Message}</b></small> : ""}
                                <small className="error">
                                    <ErrorMessage errors={errors} name="workshop_id" />
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ceasedPopup.buttons != null
                            ? ceasedPopup.buttons.map((x, index) => {
                                return (
                                    <div className={"col"} key={index}>
                                        <button
                                            className={`tom-button ${x.type}-button`}
                                            disabled={isLoading}

                                        >
                                            {x.text}
                                            {isLoading ? (
                                                <SpinnerComponent
                                                    fontColor={"text-white"}
                                                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                                                ></SpinnerComponent>
                                            ) : (
                                                ""
                                            )}
                                        </button>
                                    </div>
                                );
                            })
                            : ""}
                    </div>
                </form>
            </div>
        </div>
        {props.children}
    </CeasedPopupContext.Provider>;
}