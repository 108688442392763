export const startTimer = (
  duration,
  display,
  expired_countdown,
  expired_content
) => {
  let timer = duration;
  let seconds;
  const interval = setInterval(() => {
    seconds = parseInt(timer, 10);

    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.textContent = seconds;

    if (--timer < 0) {
      timer = duration;
    }
    if (timer === 0) {
      display.textContent = "01";
      setTimeout(() => {
        display.textContent = "00";
      }, 1000);
      clearInterval(interval);
      setTimeout(() => {
        expired_countdown.style.display = "none";
        expired_content.style.display = "block";
      }, 2000);
    }
  }, 1000);
};

export default {
  startTimer,
};
