import React from "react";
import "./welcome.scss";

export default function WelcomeComponent(props) {
  return (
    <section className="welcome-component">
      <h2>Welcome to</h2>
      <h1 style={{textTransform: "capitalize"}}>
        <b>Quartz Syiok!</b>
      </h1>
    </section>
  );
}
