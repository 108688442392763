import React from 'react';
import { Helmet } from 'react-helmet';
import seoList from '../seo.json';
import TagManager from 'react-gtm-module';
import { gtmID } from '../config/config';

export const SeoComponent = (props) => {
    const match = seoList.find(x => x.url == window.location.pathname);
    let title = "";
    let description = "";

    if (match !== null) {
        title = match.meta_title;
        description = match.meta_desc;
    }

    const tagManagerArgs = {
        gtmId: gtmID
    }
    TagManager.initialize(tagManagerArgs);

    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={window.location.origin + window.location.pathname} />
            <meta name="title" content={title} />
            <meta name="description" content={description} />
        </Helmet>
    );
}


export default SeoComponent;