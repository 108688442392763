export const apiURL = 'https://syiok.totalenergies.my:3501';
export const appURL = 'https://syiok.totalenergies.my';
export const excludeUrls= [
	'/api/login',
];
export const fb = {
    appId: "672774986653132",
}

export const google = {
    clientId: "584332170638-2c01lnng5cnufeg1og64ktgtr3rhisi6.apps.googleusercontent.com",
}
export const recaptchaKey = {
    sitekey: "6Ld1RIQcAAAAANO45tMhD90q90s_B-TkONAGETjg",
}

export const gtmID = "GTM-KG62JCG";
export const fbAdID = "1055441558192680";

// Remote logging configs
export const logger_host = 'syiok.totalenergies.my';
export const logger_port = '3501';
export const logger_path = 'api/errors/log';
