import React, {
  useRef,
  useState,
  Fragment,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory, useLocation } from "react-router-dom";
import {
  maxLengthMessage,
  minLengthMessage,
  requiredMessage,
} from "../../../../helpers/form-validation-message";
import "./mgm-form.scss";
import { apiURL } from "../../../../config/config";
import axios from "axios";
import Cookies from "js-cookie";
import querystring from "querystring";
import { UserContext } from "../../../../provider/user-provider";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import { PopupContext } from "../../../../provider/popup-provider";
import { errorMessageHandler } from "../../../../helpers/error-handler";

export default function MGMForm() {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const { register, handleSubmit, watch, errors, setError } = useForm();
  const history = useHistory();
  const tooltip_edit_phone_number = useRef();
  const { userProfile, getUserProfile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const search = useLocation().search;
  const mid = new URLSearchParams(search).get('mid');

  // Submit form
  const onSubmit = async (data) => {
    setIsLoading(true);
    let submit_content = { ...data, "birth_date": userProfile.birth_date }
    if (!isLoading) {
      try {
          const res = await Promise.all([
            axios.put(
              `${apiURL}/api/user/me`,
              querystring.stringify(submit_content),
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("access_token"),
                },
              }
            ),
            axios.put(
              `${apiURL}/api/campaign/mgm/redeem`,
              querystring.stringify(submit_content),
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("access_token"),
                },
              }
            ),
          ]);

        let response = res[1];
        
        if (response.data.status_code == 200) {
          // Update user context
          await getUserProfile();
          history.replace("/campaign/mgm/success");
        } else {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          let msg = errorMessageHandler(response.data);
          popupVal.content = [msg];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "TRY AGAIN",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
        }
      } catch (error) {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
          popupVal.img = "";
          popupVal.buttons = [
            {
              type: "primary",
              text: "OK",
              tap: () => {
                closePopup();
              },
            },
          ];

          setPopup(popupVal);
          setIsLoading(false);
      }
    }
  };

  const onTomTooltip = () => {
    tooltip_edit_phone_number.current.classList.add("active");
  };

  const onCloseTooltip = () => {
    tooltip_edit_phone_number.current.classList.remove("active");
  };

  return (
    <section className="edit-profile-form">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <img src={`/assets/images/mgm-rewards-BANNER-m.png`} alt="Mobile Banner" className="d-md-none" width="100%" />
            <img src={`/assets/images/mgm-rewards-BANNER-d.png`} alt="Desktop Banner" className="d-none d-md-block" width="100%" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="offset-0 offset-md-1 col-12 col-md-10">
            <p className="mb-0 title">
              <b>Update your details</b>
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please provide your full name as per NRIC",
                    },
                    pattern: {
                      value: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-/, ])*$/,
                      message: "*Please provide your full name as per NRIC",
                    },
                    maxLength: {
                      value: 60,
                      message: "*Please provide your full name as per NRIC",
                    },
                  })}
                  id="full-name"
                  className="tom-input"
                  name="fullname"
                  placeholder="Full Name"
                  autoComplete="off"
                  defaultValue={userProfile.fullname}
                  maxLength="60"
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="fullname" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 py-2">
              <div className="user-number">
                <span className="mobile-no">
                  {userProfile.mobile_number}&nbsp;
                  <i
                    className="icon-information"
                    onMouseOver={() => onTomTooltip()}
                    onMouseOut={() => onCloseTooltip()}
                  ></i>
                  <div className="tooltip-tom" ref={tooltip_edit_phone_number}>
                    If you need to edit your <br /> number, please contact{" "}
                    <br />
                    <span className="text-primary">Syiok! Careline.</span>
                  </div>
                </span>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please provide a valid email address",
                    },
                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "*Please provide a valid email address",
                    },
                  })}
                  id="email"
                  className="tom-input"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  defaultValue={userProfile.email}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="email" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Address"),
                    },
                    minLength: {
                      value: 8,
                      message: minLengthMessage("Address", 8),
                    },
                  })}
                  id="address"
                  className="tom-input"
                  name="address"
                  placeholder="Address"
                  autoComplete="off"
                  defaultValue={userProfile.address}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="address" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("State"),
                    },
                    minLength: {
                      value: 4,
                      message: minLengthMessage("State", 4),
                    },
                  })}
                  id="state"
                  className="tom-input"
                  name="state"
                  placeholder="State"
                  autoComplete="off"
                  maxLength="20"
                  defaultValue={userProfile.state}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="state" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Country"),
                    },
                    minLength: {
                      value: 8,
                      message: minLengthMessage("Country", 8),
                    },
                  })}
                  id="country"
                  className="tom-input"
                  name="country"
                  placeholder="Country"
                  autoComplete="off"
                  maxLength="20"
                  defaultValue={userProfile.country}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="country" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
              <div className="input-control">
                <input
                  type="number"
                  ref={register({
                    required: {
                      value: true,
                      message: requiredMessage("Postal Code"),
                    },
                    maxLength: {
                      value: 5,
                      message: maxLengthMessage("Postal Code", 5),
                    },
                  })}
                  inputMode="decimal"
                  className="tom-input"
                  name="postal_code"
                  placeholder="Postal Code"
                  autoComplete="off"
                  maxLength="5"
                  defaultValue={userProfile.postal_code}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="postal_code" />
                </small>
              </div>
            </div>
            <input
              ref={register()}
              type="hidden" name="mid"
              defaultValue={mid}
            />
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 text-center text-md-right">
              <button
                className="tom-button primary-button w-100"
                disabled={isLoading}
              >
                Update
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}