import React, { createContext, useEffect, useState } from 'react';

export const NavActiveContext = createContext();

const NavActiveContextProvider = (props) => {
    const [nav_active, setNavActive] = useState({
        active: props.current_page
    });

    return (
        <NavActiveContext.Provider value={{ nav_active }}>
            {props.children}
        </NavActiveContext.Provider>
    );
}

export default NavActiveContextProvider;