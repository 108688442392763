import moment from "moment";

export const formatDate = (date) => {
    let date_format = 'DD/MM/YYYY'
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
};

export const formatDateForm = (date) => {
    let date_format = 'YYYY-MM-DD'
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
};

export const formatDateYear = (date) => {
    let date_format = 'YYYY'
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
};

export const formatDateMonth = (date) => {
    let date_format = 'MM'
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
};

export const formatDateDay = (date) => {
    let date_format = 'DD'
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
};

export const formatDateWithTime = (date) => {
    let date_format = "DD/MM/YYYY h:mma"
    let formatted_date = moment(date).parseZone().format(date_format);

    return formatted_date;
}