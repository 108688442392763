import React from "react";
import PopupContextProvider from "../../../provider/popup-provider";
import WelcomeComponent from "../welcome/welcome";
import SurveyFormComponent from "./survey-form-component/survey-form-component";
import "./survey.scss";

export default function SurveyScreen(props) {
  return (
    <div className="survey-screen">
      <PopupContextProvider>
        <WelcomeComponent></WelcomeComponent>
        <SurveyFormComponent></SurveyFormComponent>
      </PopupContextProvider>
    </div>
  );
}
