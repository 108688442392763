import React from "react";
import { Link, useHistory } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "./how-it-works.scss";

export default function HowItWorks(props) {
  const history = useHistory();

  return (
    <section className="how-it-works-component pb-4 pb-md-5">
      <div className="container">
        <div className="content">
          <h2 className="text-md-center mb-3 mb-md-4">
            How Does It Work?
          </h2>
          <div className="row card-list pb-4 h-100">
            <div className="col-3 col-md-3">
              <Link to="/login" className="text-black">
                <div className="card">
                  <div className="px-2 px-md-4">
                    <img
                      src="/assets/images/home/sign-up.png"
                      width="100%"
                      alt="Sign-up for Quartz Syiok!"
                    />
                  </div>
                  <p className="text-center">
                    Sign-up for <br /> <b>Quartz Syiok!</b>
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-3 col-md-3">
              <div className="card">
                <div className="px-2 px-md-4">
                  <img
                    src="/assets/images/home/add-vehicle.png"
                    alt="Add vehicle"
                    width="100%"
                  />
                </div>
                <p className="text-center">
                  <b>Add</b> vehicle(s)
                </p>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="card">
                <div className="px-2 px-md-4">
                  <img
                    src="/assets/images/home/service.png"
                    alt="Service 3/4 times"
                    width="100%"
                  />
                </div>
                <p className="text-center">
                  <b>Service</b> 3/4 times
                </p>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="card">
                <div className="px-2 px-md-4">
                  <img
                    src="/assets/images/home/redeem-free-oil.png"
                    alt="Redeem your free oil"
                    width="100%"
                  />
                </div>
                <p className="text-center">
                  <b>Redeem</b> your
                  <br />
                  free oil!
                </p>
              </div>
            </div>
          </div>
          <div className="row mx-4">
            <div className="offset-2 offset-md-4 col-8 col-md-4 ">
              <button
                className="tom-button primary-button arrow"
                onClick={() => history.push("/how-it-works")}
              >
                FIND OUT MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
