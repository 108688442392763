import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import "./side-navigation.scss";
import { NavActiveContext } from "../../../provider/navbar-provider";

export default function SideNavigationComponent() {
  const history = useHistory();
  const { nav_active } = useContext(NavActiveContext);

  return (
    <div className="side-navigation-component">
      <div className="navigation-list pb-4">
        {Cookies.get("access_token") !== undefined ? (
          <div
            className={`item login ${
              nav_active.active === "loyalty" ? "active" : ""
            }`}
            onClick={() => history.push("/loyalty")}
          >
            <div className="box">
              <i className="icon-user-copy"></i>
            </div>
            <p className="mb-0 pt-2">Dashboard</p>
            <hr />
          </div>
        ) : (
          <div className="item login" onClick={() => history.push("/register")}>
            <div className="box">
              <i className="icon-sign-up"></i>
            </div>
            <p className="mb-0 pt-2">SIGN UP</p>
            <hr />
          </div>
        )}
        <div
          className={`item locator ${
            nav_active.active === "workshops_locator" ? "active" : ""
          }`}
          onClick={() => history.push("/workshops-locator")}
        >
          <i className="icon-placeholder"></i>
          <p className="mb-0">WORKSHOPS</p>
        </div>
      </div>
    </div>
  );
}
