import React, { useEffect, useState } from "react";
// import { useMediaQuery } from "./use-media-query";

export default function SpinnerComponent(props) {
  const isRowBased = useMediaQuery("(max-width: 678px)");

  const styles = {
    spinnerDimension: (isRowBased) => ({
      width: isRowBased ? props.dimensions.mobile : props.dimensions.desktop,
      height: isRowBased ? props.dimensions.mobile : props.dimensions.desktop,
      justifyContent: "space-around",
    }),
  };

  return (
    <div
      className={`spinner-border ${props.fontColor}`}
      style={styles.spinnerDimension(isRowBased)}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};
