import { logger_host, logger_port, logger_path } from "../config/config";

const winston = require('winston');
const { format } = winston;
const { combine, label, timestamp, json } = format;

const logger = winston.loggers.get('client');

// Create remote logger
export const loggerInit = () => {
    winston.loggers.add('client', {
        format: combine(
            label({ label: 'client error' }),
            timestamp(),
            json()
        ),
        transports: [
            new winston.transports.Http({
                host: logger_host,
                port: logger_port,
                path: logger_path
            })
        ]
    });
}

// Submit error log to remote location
export const submitErrorLog = (msg, level = 'error') => {
    logger.log({
        level: level,
        message: msg
    });
}

export const errorMessageHandler = (data) => {
    let msg = "Please try again.";
    
    if (data.error !== undefined) {
        if (typeof(data.error) == "object") {
            msg = JSON.stringify(data.error);
        } else {
            msg = data.error;
        }
    }

    return msg;
};