import React from "react";
import { useHistory } from "react-router-dom";
import "./content-component.scss";

export default function ContentComponent(props) {
	const history = useHistory();
	return (
		<section className="content-section">
			<div className="container">
				{/* <div className="row" style={{ marginBottom: "5rem" }}>
					<div className="col-12 col-md-6">
						<img src="/assets/images/raya-contest-thumb-d-event.jpg" alt="Win exciting prizes in the Syiok! Loyalty Spend & Win Contest today" width="100%" className="d-none d-md-block mb-3" style={{ borderRadius: "2rem" }} />
						<img src="/assets/images/raya-contest-thumb-m-event.jpg" alt="Win exciting prizes in the Syiok! Loyalty Spend & Win Contest today" width="100%" className="d-md-none mb-3" style={{ borderRadius: "1.5rem" }} />
					</div>
					<div className="col-12 col-md-6">
						<div>
							<h2 style={{ fontSize: "1.625rem", textTransform: 'none' }}>
								<b>Stand a chance to win Touch ‘n Go eWallet Reload PIN worth RM300 and a guarantee cashback of RM10!</b>
							</h2>
							<p>Simply purchase any selected Quartz products and service your vehicle from participating TotalEnergies Authorised workshop from 4 March - 14 April 2024. </p>
							<p>Submit your service record and fill up the required details to enter. Once your service record has been approved, you'll be automatically qualified for the campaign.</p>
							<p>
								For more details, read the&nbsp;
								<a href="https://totalenergies.my/system/files/atoms/files/totalenergies_ramadan_campaign_2024_terms_conditions_.pdf" target="_blank" className="text-primary">
									Terms &amp; Conditions
								</a>
								.
							</p>
							<p>&nbsp;</p>
							<h2 style={{ fontSize: "1.625rem", textTransform: 'none' }}>
								<b>Winners Announcement</b>
							</h2>
							<p>Touch ’n Go RM300 Winner List: </p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM300_Winners_8-14April.pdf"} target="_blank" className="text-primary">
									4 March - 14 April 2024
								</a>
							</p>
							<p>Touch ’n Go RM10 Winner List: </p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_4-10Mar.pdf"} target="_blank" className="text-primary">
									4 - 10 March 2024
								</a>
							</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_11-17Mar.pdf"} target="_blank" className="text-primary">
									11 - 17 March 2024
								</a>
							</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_18-24March.pdf"} target="_blank" className="text-primary">
									18 - 24 March 2024
								</a>
							</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_25-31March.pdf"} target="_blank" className="text-primary">
									25 - 31 March 2024
								</a>
							</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_1-7April.pdf"} target="_blank" className="text-primary">
									1 - 7 April 2024
								</a>
							</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/TEMMY_RiangRayaBersamaTotalEnergies_RM10_Winners_8-14April.pdf"} target="_blank" className="text-primary">
									8 - 14 April 2024
								</a>
							</p>
						</div>
					</div>
				</div> */}
				<div className="row mb-4">
					<div className="col-12 col-md-6">
						<img src="/assets/images/promotion-bg-desktop.png" alt="Refer & earn with Syiok! Loyalty Programme" width="100%" className="d-none d-md-block mb-3" style={{ borderRadius: "2rem" }} />
						<img src="/assets/images/promotion-bg.png" alt="Refer & earn with Syiok! Loyalty Programme" width="100%" className="d-md-none mb-3" style={{ borderRadius: "1.5rem" }} />
					</div>
					<div className="col-12 col-md-6">
						<div>
							<h2 style={{ fontSize: "1.625rem" }}>
								<b>On top of your free engine oil, you can also get other freebies from us!</b>
							</h2>
							<p>All you have to do is get your friends to service their vehicle with Quartz engine oil at our Quartz Auto Services (QAS).</p>
							<p>Upon the successful completion of their first service, you will be rewarded with limited edition merchandise!</p>
							<p>How to earn, you ask? Click the button below!</p>
							<p>
								<a href={process.env.PUBLIC_URL + "/assets/pdf/Quartz Syiok!_Loyalty Programme_MGM_T&C_25052023.pdf"} target="_blank" className="text-primary">
									Terms &amp; conditions apply.
								</a>
							</p>
							<button className="tom-button primary-button px-5" style={{ width: "fit-content", minWidth: "15rem" }} onClick={() => history.push("/refer-and-earn")}>
								Refer A Friend
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
