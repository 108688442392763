import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { startTimer } from "../../../../../helpers/countdown";
import "./send-otp-form.scss";
import { verifiedOTPAPI } from "./send-otp-api";
import { PopupContext } from "../../../../../provider/popup-provider";
import { useForm } from "react-hook-form";
import { validation_message } from "../../../../../data/validation_message";
import Cookie from "js-cookie";
import SpinnerComponent from "../../../../../templates/global-component/spinner-component/spinner-component";
import { UserContext } from "../../../../../provider/user-provider";
import { ErrorMessage } from "@hookform/error-message";
import TagManager from "react-gtm-module";

export default function SendOtpFormComponent(props) {
  const { popup, setPopup, closePopup } = useContext(PopupContext);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const { getUserProfile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const otpRef = useRef("");
  const [otpValue, setOtpValue] = useState("");

  useEffect(() => {
    countDownOtp();
  }, []);

  const countDownOtp = () => {
    const threeMinutes = 60 * 3,
      display = document.querySelector("#time"),
      expired_countdown = document.getElementById("expired-coundown"),
      expired_content = document.getElementById("expired-content");
    expired_countdown.style.display = "block";
    expired_content.style.display = "none";
    startTimer(threeMinutes, display, expired_countdown, expired_content);
  };

  const onChangeOtpNumber = () => {
    let otp_value = "";
    otpRef.current.forEach((input) => (otp_value = otp_value + input.value));
    setOtpValue(otp_value);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isLoading) {
      const otpResult = await verifiedOTPAPI(
        props.register_phone,
        data.otp_field
      );

      if (otpResult.status_code == 200) {
        TagManager.dataLayer({ dataLayer: { event: 'Reg OTP verification', cta_button: 'OTP Verify' } });

        Cookie.set("access_token", otpResult.result.access_token, {
          expires: 180,
        });
        Cookie.set("refresh_token", otpResult.result.refresh_token, {
          expires: 180,
        });

        // Update user context
        await getUserProfile();

        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.img = "success-icon";
        popupVal.content = [
          "Thank you! Your registration is complete. Next up: register your vehicle.",
        ];
        popupVal.buttons = [
          {
            type: "primary",
            text: "OK",
            tap: () => {
              TagManager.dataLayer({ dataLayer: { event: 'Reg successful', cta_button: 'Reg successful' } });
              sessionStorage.setItem("survey", true);
              window.location.replace("/register/survey");
            },
          },
        ];
        setIsLoading(false);
        setPopup(popupVal);
      } else if (otpResult.status_code == 500) {
          let popupVal = Object.assign({}, popup);
          popupVal.open = true;
          popupVal.img  = "danger-icon";
          popupVal.content = [
            "Oops! Look like you're entered an incorrect verification code",
          ];
          popupVal.buttons = [
            {
              type: "primary",
              text: "TRY AGAIN",
              tap: () => {
                closePopup();
              },
            },
          ];
          setIsLoading(false);
          setPopup(popupVal);
        }  else if (otpResult.status_code == 502) {
          let popupVal = Object.assign({}, popup);
          popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
          popupVal.img = "danger-icon";
          popupVal.buttons = [
            {
              type: "primary",
              text: "CLOSE",
              tap: () => {
                closePopup();
              },
            },
          ];
          setPopup(popupVal);
          setIsLoading(false);
        }
    }
  };

  return (
    <section className="send-otp-register-component">
      <div className="container">
        <div className="content">
          <div className="row mb-4">
            <div className="col-12">
              <h2 className="mb-0 title">
                <b>Verification</b>
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <p className="text-center information">
                A 6 digit verification code has been sent to <br className="d-none d-md-block" /> your mobile
                number: &nbsp;
                <b id="mobile-no" className="text-primary"></b>
                <br className="d-none d-md-block" /> Please enter the code below.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-3">
              <div className="input-control">
                <input
                  type="number"
                  ref={register({
                    required: {
                      value: true,
                      message:
                        "*" + validation_message.register_tac.pattern.message,
                    },
                    minLength: {
                      value: 6,
                      message:
                        "*" + validation_message.register_tac.pattern.message,
                    },
                  })}
                  id="otp_field"
                  name="otp_field"
                  inputMode="decimal"
                  className="tom-input"
                  onChange={(e) => {
                    if (e.target.value.toString().length > 6) {
                      return (e.target.value = e.target.value.substr(0, 6));
                    }
                  }}
                />

                <small className="error">
                  <ErrorMessage errors={errors} name="otp_field" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2 mt-md-3 mb-md-2">
              <button
                className="tom-button primary-button"
                disabled={isLoading}
              >
                Verify
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mt-md-3 mb-md-2">
              <button
                className="tom-button default-button"
                id="go-back"
                onClick={() => {
                  TagManager.dataLayer({ dataLayer: { event: 'Reg OTP verification', cta_button: 'OTP Go Back' } });
                  history.goBack();
                }}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="row px-md-5">
            <div className="col-12">
              <p className="information text-center">
                Don't receive the verification code? <br />
                Please check that you entered the <br />
                correct mobile number.
              </p>
              <p className="information text-center">
                <i>
                  <b>or</b>
                </i>
              </p>
              <p id="expired-coundown" className="information text-center">
                <span>Resend</span> code in <span id="time">00</span> seconds
              </p>
              <p
                id="expired-content"
                className="text-center"
                onClick={() => countDownOtp()}
              >
                Resend Code
              </p>
              <p className="information text-center">
                If you still don't receive the verification code, <br />
                <span>contact support.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}