import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppRoute from "./approute";
import EditProfileScreen from "./screens/dashboard-screen/edit-profile/edit-profile";
import InboxScreen from "./screens/dashboard-screen/inbox/inbox";
import LoyaltyScreen from "./screens/dashboard-screen/loyalty-dashboard/loyalty-dashboard-screen/loyalty-dashboard";
import ServiceInformationScreen from "./screens/dashboard-screen/loyalty-dashboard/service-information-screen/service-information-screen";
import AddServiceScreen from "./screens/dashboard-screen/loyalty-dashboard/add-service-screen/add-service-screen";
import AddVehicleScreen from "./screens/dashboard-screen/loyalty-dashboard/add-vehicle-screen/add-vehicle-screen";
import LoginTemplate from "./templates/login/login-template";
import LoginScreen from "./screens/login/login-screen/login-screen";
import ForgotPasswordScreen from "./screens/login/forgot-password/forgot-password";
import SendOtpForgotPasswordScreen from "./screens/login/forgot-password/send-otp/send-otp-screen";
import EmailRegisterationScreen from "./screens/login/register/email-registeration/email-registeration-screen";
import SendOtpScreen from "./screens/login/register/send-otp/send-otp-screen";
import SurveyScreen from "./screens/login/survey/survey";
import NonLoginTemplate from "./templates/non-login/non-login-template";
import HomeScreen from "./screens/general-screen/home/home";
import PromotionScreen from "./screens/general-screen/promotion/promotion";
import WorkshopLocatorScreen from "./screens/general-screen/workshops/workshop-locator";
import AboutUsScreen from "./screens/general-screen/about-us/about-us";
import HowItWorksScreen from "./screens/general-screen/how-it-works/how-it-works";
import FaqScreen from "./screens/general-screen/faq/faq";
import SupportScreen from "./screens/general-screen/support/support";
import ReferEarnScreen from "./screens/dashboard-screen/refer-earn/refer-earn";
import FullSynteticScreen from "./screens/general-screen/full-syntetic/full-syntetic";
import SemiSynteticScreen from "./screens/general-screen/semi-syntetic/semi-syntetic";
import Cookie from "js-cookie";
import PageNotFoundTemplate from "./templates/404/404";
import SubscribeScreen from "./screens/general-screen/subscribe/subscribe";
import BlankTemplate from "./templates/blank/blank-template";
import MemberGetMemberScreen from "./screens/dashboard-screen/mgm/mgm";
import MemberGetMemberSuccessScreen from "./screens/dashboard-screen/mgm-success/mgm-success";
import PrivateRoute from "./private-route";
import withClearCache from "./ClearCache";
import LogoutScreen from "./screens/dashboard-screen/logout/logout";
import PrivacyPolicyScreen from "./screens/general-screen/privacy-policy/privacy-policy";
import RayaCampaignScreen from "./screens/dashboard-screen/raya-campaign/raya-campaign";

const dashboard_routes = [
  {
    path: "/register/survey",
    layout: NonLoginTemplate,
    component: SurveyScreen,
    nav_active: "",
  },
  {
    path: "/edit-profile",
    layout: NonLoginTemplate,
    component: EditProfileScreen,
    nav_active: "",
  },
  {
    path: "/inbox",
    layout: NonLoginTemplate,
    component: InboxScreen,
    nav_active: "inbox",
  },
  {
    path: "/loyalty",
    layout: NonLoginTemplate,
    component: LoyaltyScreen,
    nav_active: "loyalty",
  },
  {
    path: "/logout",
    layout: NonLoginTemplate,
    component: LogoutScreen,
    nav_active: "",
  },
  {
    path: "/loyalty/service-information",
    layout: NonLoginTemplate,
    component: ServiceInformationScreen,
    nav_active: "",
  },
  {
    path: "/loyalty/add-service",
    layout: NonLoginTemplate,
    component: AddServiceScreen,
    nav_active: "",
  },
  {
    path: "/loyalty/add-new-vehicle",
    layout: NonLoginTemplate,
    component: AddVehicleScreen,
    nav_active: "",
  },
  {
    path: "/refer-and-earn",
    layout: NonLoginTemplate,
    component: ReferEarnScreen,
    nav_active: "refer_and_earn",
  },
  {
    path: "/campaign/mgm",
    layout: NonLoginTemplate,
    component: MemberGetMemberScreen,
    nav_active: "",
  },
  {
    path: "/campaign/mgm/success",
    layout: NonLoginTemplate,
    component: MemberGetMemberSuccessScreen,
    nav_active: "",
  },
];

const login_routes = [
  {
    path: "/login",
    layout: LoginTemplate,
    component: LoginScreen,
    nav_active: "login",
  },
  {
    path: "/forgot-password",
    layout: LoginTemplate,
    component: ForgotPasswordScreen,
    nav_active: "",
  },
  {
    path: "/forgot-password/tac-number",
    layout: LoginTemplate,
    component: SendOtpForgotPasswordScreen,
    nav_active: "",
  },
  {
    path: "/register",
    layout: LoginTemplate,
    component: EmailRegisterationScreen,
    nav_active: "",
  },
  {
    path: "/register/tac-number",
    layout: LoginTemplate,
    component: SendOtpScreen,
    nav_active: "",
  },
];

const general_routes = [
  {
    path: "/",
    layout: NonLoginTemplate,
    component: withClearCache(HomeScreen),
    nav_active: "home",
  },
  {
    path: "/about-us",
    layout: NonLoginTemplate,
    component: AboutUsScreen,
    nav_active: "about_us",
  },
  {
    path: "/how-it-works",
    layout: NonLoginTemplate,
    component: HowItWorksScreen,
    nav_active: "how_it_works",
  },
  {
    path: "/faq",
    layout: NonLoginTemplate,
    component: FaqScreen,
    nav_active: "faq",
  },
  {
    path: "/privacy-policy",
    layout: NonLoginTemplate,
    component: PrivacyPolicyScreen,
    nav_active: "privacy_policy",
  },
  {
    path: "/promotion",
    layout: NonLoginTemplate,
    component: PromotionScreen,
    nav_active: "promotion",
  },
  {
    path: "/subscribe",
    layout: BlankTemplate,
    component: SubscribeScreen,
    nav_active: "contact_support",
  },
  {
    path: "/contact-support",
    layout: NonLoginTemplate,
    component: SupportScreen,
    nav_active: "contact_support",
  },
  {
    path: "/workshops-locator",
    layout: NonLoginTemplate,
    component: WorkshopLocatorScreen,
    nav_active: "workshops_locator",
  },
  {
    path: "/buy3get1",
    layout: NonLoginTemplate,
    component: FullSynteticScreen,
    nav_active: "full_syntetic",
  },
  {
    path: "/buy4get1",
    layout: NonLoginTemplate,
    component: SemiSynteticScreen,
    nav_active: "semi_syntetic",
  },
];

export const routesComponent = () => {
  let routes = [];
  let privateRoutes = [];
  
  if (Cookie.get("access_token") != null) {
    routes.push(...general_routes);
  } else {
    routes.push(...general_routes, ...login_routes);
  }
  privateRoutes.push(...dashboard_routes);

  const auth_routes = privateRoutes.map(
    ({ path, layout, component, nav_active }, index) => (
      <PrivateRoute
        exact
        path={path}
        layout={layout}
        component={component}
        key={index}
        nav_active={nav_active}
      ></PrivateRoute>
    )
  );

  const route_components = routes.map(
    ({ path, layout, component, nav_active }, index) => (
      <AppRoute
        exact
        path={path}
        layout={layout}
        component={component}
        key={index}
        nav_active={nav_active}
      ></AppRoute>
    )
  );

  return (
    <Router>      
      <Switch>
        {route_components}
        {auth_routes}
        <Route
          component={PageNotFoundTemplate}
        />
      </Switch>
    </Router>
  );
};

export default {
  routesComponent,
};
