import React from "react";
import { Link, useHistory } from "react-router-dom";
import TopSectionScreen from "../top-section/top-section";
import "./support.scss";

export default function SupportScreen(props) {
  const history = useHistory();
  const openUrl = (url) => {
    window.open(url);
  };

  return (
    <div className="support-screen">
      <TopSectionScreen title="Support"></TopSectionScreen>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12 offset-md-2 col-md-8 text-center">
              <p>
                Hold up! Have you seen our FAQ? <br />
                <Link to="/faq" className="text-primary">
                  Click here
                </Link>{" "}
                to see if your inquiries are covered.
              </p>
              <p>
                If you’re still unable resolve your issue, you may contact us at
                our Quartz Syiok! Careline:
              </p>

              <a
                className="careline text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("tel:0126782283");
                }}
              >
                <i className="icon-phone pr-2 pr-md-3"></i>012 678 2283
              </a>
              <p className="mt-2">Operating hours: Monday to Sunday, 9am–5pm</p>
              <p>OR</p>
              <p>Drop us an email at: </p>
              <p className="mb-4">
                <a
                  className="email text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => openUrl("mailto:customersupport@total.com")}
                >
                  <i className="icon-email pr-2 pr-md-3"></i>
                  customersupport@totalenergies.com
                </a>
              </p>

              <button
                className="tom-button primary-button"
                onClick={(e) => history.replace("/loyalty")}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
