import React, { useContext, useEffect, useState } from "react";
import { WorkshopLocatorContext } from "../map-context";
import "./map-search.scss";
import Autocomplete from "../autocomplete-component/autocomplete-component";

export default function MapSearch(props) {
  const {
    locator,
    setLocator,
    marker_list_api,
    marker_list,
    setMarkerList,
  } = useContext(WorkshopLocatorContext);
  const [filter_result, setFilterResult] = useState([]);
  const [filter_marker, setFilterMarker] = useState(0);
  const [dropdown_status, setDropdownStatus] = useState(false);

  const setCurrentPosition = () => {
    let locatorInfo = Object.assign({}, locator);
    locatorInfo.center_position.lat =
      +locatorInfo.current_position.lat - 0.0000001;
    locatorInfo.center_position.log = locatorInfo.current_position.log;
    setLocator(locatorInfo);
  };

  const toggleDropdown = () => {
    !dropdown_status ? setDropdownStatus(true) : setDropdownStatus(false);
  };

  const chooseFilter = (type) => {
    setFilterMarker(type);
    setDropdownStatus(false);
    setFilterResult(
        marker_list_api.filter((x) =>
        type === 1 ? x.tqac_workshop === true : x.tqac_workshop === false
      )
    );
    const filtered_position = marker_list_api.filter((x) =>
      type === 1 ? x.tqac_workshop === true : x.tqac_workshop === false
    );
    setMarkerList(filtered_position);
  };

  return (
    <section className="search-form-section">
      <div className="container">
        <div className="row">
          <div className="col-10 col-md-11 pr-2">
            <div className="search-group">
              <Autocomplete
                suggestions={filter_marker === 0 ? marker_list : filter_result}
              />
            </div>
          </div>
          <div className="col-2 col-md-1 px-2 py-1">
            <span className="filter-dropdown">
              <i className="icon-filter" onClick={() => toggleDropdown()} />
              <ul className={dropdown_status ? "active" : ""}>
                <li
                  className={filter_marker === 1 ? "active" : ""}
                  onClick={() => chooseFilter(1)}
                >
                  Quartz Auto Care
                </li>
                <li
                  className={filter_marker === 2 ? "active" : ""}
                  onClick={() => chooseFilter(2)}
                >
                  Non Quartz Auto Care
                </li>
              </ul>
            </span>
            &nbsp;&nbsp;
            <i className="icon-target" onClick={() => setCurrentPosition()} />
          </div>
        </div>
      </div>
    </section>
  );
}
