import Axios from "axios";
import Cookies from 'js-cookie';
import React, { createContext, useState, useEffect } from "react";
import { apiURL } from "../config/config";

export const InboxContext = createContext();

const InboxContextProvider = props => {
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [inboxIndicator, setInboxIndicator] = useState(0);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const getMessages = async () => {
            try {
                const response = await Axios.get(
                    `${apiURL}/api/user/notifications`,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('access_token')
                        }
                    }
                );

                if (response.data.result != null) {
                    let _message = response.data.result;
                    // Get count of unread messages
                    const count = _calculateUnreadMessages(_message);

                    setMessages(_message);
                    setInboxIndicator(count);
                }

                setTimeout(() => setLoadingIndicator(false), 1000);
            } catch (error) {
                console.log(error);
            }
        }

        if (Cookies.get('access_token') != null) {
            getMessages();
        }
    }, []);

    return (
        <InboxContext.Provider value={{ loadingIndicator, messages, inboxIndicator }}>
            {props.children}
        </InboxContext.Provider>
    );
};

const _calculateUnreadMessages = (messages) => {
    let count = messages.reduce((count, obj) => obj.is_read === false ? ++count : count, 0);
    return count;
};

export default InboxContextProvider;